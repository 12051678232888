import {
    Chip,
    RadioGroup,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import {
    AgentGroupingOptions,
    default as IntegrationConfiguration,
    IntegrationType,
} from "models/OrganizationModels/OrganizationIntegrationConfiguration";
import moment from "moment";
import React from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { IntegrationStore } from "./IntegrationStore";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import { toIso } from "utils/DateTimeUtils";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import AcxSwitch from "components/UI/AcxSwitch";

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            marginTop: 16,
        },
        fieldSpacing: {
            margin: "0.25rem 0",
        },
        itemSpacing: { margin: "1rem", padding: "1rem" },
        inputSpacing: { paddingRight: "0.5rem" },
        calendarInputSpacing: { paddingRight: "16px" },
    });
};

type Props = {
    item: IntegrationConfiguration;
    index: number;
};

const IntegrationSettingItem: React.FC<Props> = observer(({ item, index }) => {
    const classes = useStyles(styles);

    const integrationStore = useStore(IntegrationStore);

    const hasMaxAndMin =
        item.configuration?.minimumCallLengthInSeconds !== null &&
        item.configuration?.minimumCallLengthInSeconds !== undefined &&
        item.configuration?.maximumCallLengthInSeconds !== null &&
        item.configuration?.maximumCallLengthInSeconds !== undefined;

    const hasMinCallLengthError =
        hasMaxAndMin &&
        item.configuration!.minimumCallLengthInSeconds! >=
            item.configuration!.maximumCallLengthInSeconds!;

    const hasMaxCallLengthError =
        hasMaxAndMin &&
        item.configuration?.maximumCallLengthInSeconds! <=
            item.configuration?.minimumCallLengthInSeconds!;

    const callAgentGroupingOptions =
        EnumToDropdownOptionConversion(AgentGroupingOptions);

    React.useEffect(() => {
        if (hasMaxCallLengthError || hasMinCallLengthError) {
            integrationStore.setHasError(true);
        } else {
            integrationStore.setHasError(false);
        }
    }, [hasMaxCallLengthError, hasMinCallLengthError, integrationStore]);

    return (
        <Paper className={classes.itemSpacing} key={item.id}>
            <Grid container item xs={12}>
                <Grid item xs={12} className={classes.fieldSpacing}>
                    <AcxInputLabel>Provider</AcxInputLabel>
                    <RadioGroup
                        onChange={(e) => {
                            integrationStore.updateType(
                                index,
                                e.target.value as IntegrationType,
                            );
                        }}
                        value={item.type}
                    >
                        <Grid container item xs={12}>
                            <FormControlLabel
                                value={IntegrationType.NiceIn}
                                control={<Radio />}
                                label="NiceIn"
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                value={IntegrationType.GenesysCloud}
                                control={<Radio />}
                                label="Genesys Cloud"
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                value={IntegrationType.SalesForce}
                                control={<Radio />}
                                label="Salesforce"
                                labelPlacement="end"
                            />
                        </Grid>
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} className={classes.fieldSpacing}>
                    <AcxMainTextField
                        labelText="URL"
                        id={"api-url-" + index}
                        value={item.url}
                        onChange={(event) =>
                            integrationStore.updateUrl(
                                index,
                                event.target.value,
                            )
                        }
                        type="url"
                    />
                </Grid>
                <Grid item xs={12} className={classes.fieldSpacing}>
                    <AcxMainTextField
                        labelText="API Key"
                        id={"api-key-" + index}
                        value={item.apiKey}
                        onChange={(event) =>
                            integrationStore.updateApiKey(
                                index,
                                event.target.value,
                            )
                        }
                    />
                </Grid>

                <Grid item xs={12} className={classes.fieldSpacing}>
                    <AcxMainTextField
                        labelText="API Secret"
                        id={"api-secret-" + index}
                        value={item.apiSecret}
                        onChange={(event) =>
                            integrationStore.updateApiSecret(
                                index,
                                event.target.value,
                            )
                        }
                    />
                </Grid>

                {item.type === IntegrationType.GenesysCloud && (
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.fieldSpacing}
                        >
                            <Grid item xs={3} className={classes.inputSpacing}>
                                <AcxMainTextField
                                    labelText="Minimum Call Length (Seconds)"
                                    id={"api-secret-" + index}
                                    value={item.configuration?.minimumCallLengthInSeconds?.toString()}
                                    onChange={(event) =>
                                        integrationStore.updateCallLength(
                                            "min",
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    type="number"
                                    insertBlankIfNumber
                                    showAllErrors
                                    error={hasMinCallLengthError}
                                    errorHelper="Minimum call length must be greater than maximum call length"
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.inputSpacing}>
                                <AcxMainTextField
                                    labelText="Maximum Call Length (Seconds)"
                                    id={"api-secret-" + index}
                                    value={item.configuration?.maximumCallLengthInSeconds?.toString()}
                                    onChange={(event) =>
                                        integrationStore.updateCallLength(
                                            "max",
                                            index,
                                            event.target.value,
                                        )
                                    }
                                    type="number"
                                    insertBlankIfNumber
                                    error={hasMaxCallLengthError}
                                    errorHelper="Maximum call length must be greater than minimum call length"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                className={classes.calendarInputSpacing}
                            >
                                <AcxCalendarPopup
                                    id="test"
                                    inputLabel="No calls prior to date"
                                    onSelect={(date) => {
                                        integrationStore.updatePriorCallDate(
                                            index,
                                            toIso(date.utc()),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.inputSpacing}>
                                <AcxSelect
                                    id="int-agent-grouping-select"
                                    options={callAgentGroupingOptions}
                                    inputLabel="Call Agent Grouping Option"
                                    defaultValue={callAgentGroupingOptions.find(
                                        (option) =>
                                            option.value ===
                                            item.configuration
                                                ?.customConfiguration
                                                ?.agentGroupingOptions,
                                    )}
                                    onChange={(item) => {
                                        integrationStore.handleAgentGroupingUpdates(
                                            index,
                                            item.value,
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <AcxInputLabel>
                            Queue IDs to pull calls from
                        </AcxInputLabel>
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.fieldSpacing}
                        >
                            <Grid
                                item
                                container
                                xs={1}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <IconButton
                                    onClick={() => {
                                        item.configuration?.customConfiguration?.queueIds.pop();
                                    }}
                                    size="small"
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        integrationStore.addQueueId(index);
                                    }}
                                    size="small"
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </Grid>
                            <Grid item container xs={11}>
                                {!!item.configuration?.customConfiguration
                                    ?.queueIds?.length &&
                                    item.configuration?.customConfiguration?.queueIds.map(
                                        (queueId, stringIndex) => (
                                            <Grid
                                                item
                                                xs={3}
                                                className={classes.inputSpacing}
                                                key={stringIndex}
                                            >
                                                <AcxMainTextField
                                                    labelText="Queue Id"
                                                    id={"qId" + stringIndex}
                                                    value={queueId}
                                                    onChange={(event) =>
                                                        integrationStore.handleQueueIdUpdates(
                                                            index,
                                                            stringIndex,
                                                            event.target.value,
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ),
                                    )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid
                item
                container
                xs={12}
                className={classes.fieldSpacing}
                style={{
                    justifyContent: "space-between",
                }}
            >
                <Grid
                    item
                    container
                    xs={4}
                    style={{ alignItems: "center", minWidth: "100px" }}
                >
                    <AcxSwitch
                        checked={item.isActive}
                        onChange={() => integrationStore.toggleActive(index)}
                    />

                    <Chip
                        label={item.isActive ? "Active" : "Inactive"}
                        color={item.isActive ? "primary" : "default"}
                    />
                </Grid>
                <Grid
                    item
                    xs={1}
                    container
                    style={{
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton
                        onClick={() => integrationStore.deleteItem(index)}
                        size="large"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.fieldSpacing}>
                <Typography>
                    Last Pulled:{" "}
                    {!item.lastUpdate || moment(item.lastUpdate).year() < 1995
                        ? "N/A"
                        : moment(item.lastUpdate).format(
                              "M/DD/YYYY h:mm a",
                          )}{" "}
                </Typography>
            </Grid>
        </Paper>
    );
});

export default IntegrationSettingItem;
