import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import AcxSwitch from "components/UI/AcxSwitch";

export const FavoriteConversationsFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <AcxSwitch
                id="application-filters-favorite-conversations"
                checked={props.store.favoriteConversations}
                onChange={() => props.store.toggleFavoriteConversationsFilter()}
                sx={{ marginLeft: 0 }}
            />
            <Typography>Show favorite conversations only</Typography>
        </div>
    );
});
