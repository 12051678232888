import React from "react";
import OrganizationRolesList from "./OrganizationRolesList";

type Props = {
    id: string;
};

const OrganizationRoles: React.FC<Props> = ({ id }) => {
    return <OrganizationRolesList orgId={id} />;
};

export default OrganizationRoles;
