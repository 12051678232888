import { FormControlLabel, Grid, Typography } from "@mui/material";
import { Pagination } from "@mui/material";
import {
    AnswerType,
    Question,
    QuestionCategory,
} from "components/Admin/Organizations/types/Module.type";
import AcxButton from "components/UI/AcxButton";
import AcxErrorMessage from "components/UI/AcxErrorMessage";
import { AnswerTypeNames } from "models/AnswerType";
import React, { useEffect, useState } from "react";
import { arrayMove } from "shared/functions/arrayMove";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import { v4 as uuidv4 } from "uuid";
import { requiredOptions } from "../modules-constants";
import OrganizationModuleQuestion from "./OrganizationModuleQuestion";
import { useStore } from "utils/useStore";
import { OrganizationModuleDetailsStore } from "./OrganizationModuleDetailsStore";
import AcxSwitch from "components/UI/AcxSwitch";

type Props = {
    initialQuestions?: Question[];
    thingsAreLoading: boolean;
    answerTypes?: AnswerType[];
    errors?: FormFieldErrors;
    questionErrors?: any;
    onChange: any;
    addTagsToDelete: (id: string | string[]) => void;
    nested?: boolean;
    parentWeight?: number;
    errorPath?: string;
    serverErrors?: FormFieldErrors;
    error?: boolean;
    helperText?: string;
    organizationId: string;
    questionCategories?: QuestionCategory[] | undefined;
};

const OrganizationModuleQuestions: React.FC<Props> = ({
    organizationId,
    initialQuestions = [],
    thingsAreLoading,
    answerTypes,
    errors,
    questionErrors,
    onChange,
    addTagsToDelete,
    nested = false,
    parentWeight = 0,
    errorPath = "",
    serverErrors,
    questionCategories,
}) => {
    const [questions, setQuestions] = useState<Question[]>(
        initialQuestions.filter((question) => question.isActive),
    );
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [pageNum, setPageNum] = useState<number>(1);

    const store = useStore(OrganizationModuleDetailsStore);

    const itemsPerPage: number = 10;
    const startItemIndex: number = (pageNum - 1) * itemsPerPage;
    const pageCount: number = Math.ceil(questions.length / itemsPerPage);

    const atPageEnd = questions.length === itemsPerPage * pageNum;
    const atPageStart = questions.length === itemsPerPage * (pageNum - 1) + 1;

    useEffect(() => {
        onChange(questions);
    }, [questions, onChange]);

    useEffect(() => {
        // This is only needed for the top level safety event module questions
        if (!nested) {
            setQuestions(initialQuestions);
        }
    }, [initialQuestions, nested]);

    const toggleShowInactive = () => {
        setShowInactive((currentVal) => {
            if (currentVal) {
                setQuestions(
                    initialQuestions.filter((question) => question.isActive),
                );
            } else {
                setQuestions(initialQuestions);
            }

            return !currentVal;
        });
    };

    const addQuestion = () => {
        setQuestions((prev) =>
            prev.concat({
                questionText: "",
                answerType: (answerTypes || [])[0],
                helperText: "",
                category: "",
                weight: parentWeight,
                required: requiredOptions[0],
                $uiKey: uuidv4(),
                isActive: true,
                isNew: true,
                id: uuidv4(),
            }),
        );
        if (atPageEnd) {
            setPageNum(pageCount + 1);
        } else if (pageCount !== 0 && pageNum !== pageCount) {
            setPageNum(pageCount);
        }
    };

    const removeQuestion = (question: Question) => {
        setQuestions((prev) =>
            prev.filter((q) => q.$uiKey !== question.$uiKey),
        );
        if (atPageStart && pageNum !== 1) {
            setPageNum((prev) => prev - 1);
        }
    };

    const duplicateQuestion = (index: number) => {
        setQuestions((prev) => {
            const questionsCopy = JSON.parse(JSON.stringify(prev));
            const copy = JSON.parse(JSON.stringify(questionsCopy[index]));
            delete copy.id;
            if (copy.isDefault) {
                copy.isDefault = false;
            }
            copy.$uiKey = uuidv4();
            questionsCopy.push(copy);
            return questionsCopy;
        });
        if (atPageEnd) {
            setPageNum(pageCount + 1);
        } else if (pageNum !== pageCount) {
            setPageNum(pageCount);
        }
    };

    const moveQuestion = (previousIndex: number, newIndex: number) => {
        setQuestions((prev) => {
            const questionsCopy = JSON.parse(JSON.stringify(prev));
            arrayMove(questionsCopy, previousIndex, newIndex);
            return questionsCopy;
        });
    };

    const onChangeQuestion = (index: number, val: Question) => {
        setQuestions((prev) => {
            const prevQuestion = JSON.stringify(prev[index]);
            const newQuestion = JSON.stringify(val);
            if (prevQuestion !== newQuestion) {
                const prevQuestions = JSON.parse(JSON.stringify(prev));
                prevQuestions[index] = val;
                return prevQuestions;
            } else {
                return prev;
            }
        });
    };

    const dependencyQuestions = questions.filter(
        (_q) =>
            _q.answerType.answerTypeName !== AnswerTypeNames.QuestionGrouping &&
            !_q.answerType.isFillInAnswer,
    );

    return (
        <>
            <Grid container style={{ paddingBottom: 10 }}>
                <Grid item xs={8}>
                    {nested ? (
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: 10,
                            }}
                        >
                            Sub-questions
                        </Typography>
                    ) : (
                        <Typography
                            variant="h5"
                            style={{
                                paddingBottom: 24,
                                paddingTop: 10,
                            }}
                        >
                            {store.lmType === 3
                                ? "Safety Event Module Questions"
                                : "Questions"}
                        </Typography>
                    )}
                </Grid>
                <Grid item container xs={4} justifyContent="flex-end">
                    <FormControlLabel
                        control={
                            <AcxSwitch
                                onChange={toggleShowInactive}
                                defaultChecked={false}
                            />
                        }
                        label="Display Inactive"
                    />
                </Grid>
            </Grid>
            {questions.map((question, i) => (
                <OrganizationModuleQuestion
                    organizationId={organizationId}
                    key={question.$uiKey}
                    initialValue={question}
                    index={i}
                    onChangeQuestion={onChangeQuestion.bind(null, i)}
                    moveQuestion={moveQuestion}
                    numberOfQuestions={questions?.length || 0}
                    duplicateQuestion={duplicateQuestion}
                    answerTypesIsLoading={thingsAreLoading}
                    questionCategories={questionCategories}
                    answerTypes={answerTypes}
                    errors={{
                        ...errors,
                        ...serverErrors,
                        ...questionErrors,
                    }}
                    addTagsToDelete={addTagsToDelete}
                    errorPath={`${
                        errorPath ? `${errorPath}.` : ""
                    }Questions[${i}]`}
                    showInactive={showInactive}
                    removeQuestion={removeQuestion}
                    dependencyQuestions={dependencyQuestions}
                    startItemIndex={startItemIndex}
                    itemsPerPage={itemsPerPage}
                />
            ))}

            {questions.length > itemsPerPage && (
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    style={{ padding: "8px" }}
                >
                    <Pagination
                        count={pageCount}
                        onChange={(event, value) => {
                            setPageNum(value);
                        }}
                        page={pageNum}
                    />
                </Grid>
            )}

            <AcxErrorMessage errors={errors?.questions} />
            <AcxErrorMessage
                errors={serverErrors?.[`${errorPath}.Questions`]}
            />
            <AcxErrorMessage errors={serverErrors?.[`${errorPath}.Tags`]} />
            <AcxErrorMessage errors={errors?.[`${errorPath}.Questions`]} />
            <AcxErrorMessage errors={errors?.[`${errorPath}.Tags`]} />

            <div
                style={{
                    paddingTop: 24,
                    borderTop: "1px solid #e5e5e5",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "inline-flex",
                    }}
                >
                    <AcxButton
                        loading={thingsAreLoading}
                        fullWidth
                        onClick={addQuestion}
                        color="primary"
                    >
                        Add Question
                    </AcxButton>
                </div>
            </div>
        </>
    );
};

export default OrganizationModuleQuestions;
