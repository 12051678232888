import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface IFileMetaDialog {
    open: boolean;
    onCloseClick: () => void;
    title: string;
}

export default function FileMetaDialog(props: IFileMetaDialog) {
    const handleClose = () => {
        props.onCloseClick();
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={false}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent style={{ minHeight: "400px" }}></DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
