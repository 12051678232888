import CachedIcon from "@mui/icons-material/Cached";
import {
    Button,
    ButtonGroup,
    FormControlLabel,
    Grid,
    Theme,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ManagerInteractionsStore, {
    LOAD_ASSIGNED,
    SelectionOption,
} from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import AcxButton from "components/UI/AcxButton";
import AcxChip from "components/UI/AcxChip";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    dateColumnType,
    interactionDateColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { durationColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DurationColType";
import { statusColumnType } from "components/UI/AcxDataGrid/ColumnTypes/StatusColType";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import AcxHierarchyDetailsFab from "components/UI/HierarchyDetailsFab/AcxHierarchyDetailsFab";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { evaluationNameFromBlobFileKey } from "utils/Utils";
import { InteractionDateReferenceOption } from "../../../../stores/ComponentStores/DatePickerComponentStore";
import { useResizeObserver } from "../../../../utils/useResizeObserver";
import AssignAnalystDialog from "./AssignAnalystDialog";
import SummaryDisplay from "./SummaryDisplay";
import UnAssignAnalystDialog from "./UnAssignAnalystDialog";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Routes } from "components/Navigation/Routes";
import AcxSwitch from "components/UI/AcxSwitch";

const useStyles = makeStyles((theme: Theme) => ({
    queueExpansionPanelClosed: {
        backgroundColor: "unset",
        color: theme.palette.info.dark,
        fontWeight: "bold",
    },
    queueExpansionPanelOpen: {
        backgroundColor: "unset",
        color: theme.palette.info.dark,
        fontWeight: "bold",
    },
    expansionPanelChevron: {
        color: theme.palette.black.main,
    },
    queueNameContainer: {
        marginBottom: theme.spacing(1.25),
        fontSize: "13px",
    },
    lowestHierarchyLevel: {
        color: "inherit",
    },
    fabText: {
        marginLeft: "8px",
        color: "inherit",
        fontSize: "inherit",
        fontWeight: 600,
        textTransform: "none",
    },
    tableButton: {
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "13px",
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        whiteSpace: "nowrap",
        minWidth: "max-content",
    },
    ulAlign: {
        paddingInlineStart: "1.75rem",
    },
    empty: {},
    groupRoot: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "#E5E7EB",
        border: "none",
        borderRadius: "8px",
        // padding: "4px",
        gap: "8px",
    },
    grouped: {
        flex: "0 1 auto",
        border: "none",
    },
    btnRoot: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        userSelect: "none",
        textTransform: "none",
        backgroundColor: theme.palette.lightgray.background,
        color: theme.palette.blackFont.main,
        fontFamily: theme.typography.fontFamily,
        fontSize: "13px",
        lineHeight: "20px",
        textAlign: "center",
        fontWeight: 600,
        border: "none!important",
        borderRadius: "4px!important",
        "&:hover": {
            backgroundColor: theme.palette.lightgrayHover.main,
        },
        "&$selected": {
            backgroundColor: theme.palette.white.main,
            border:
                "1px solid" + theme.palette.lightgrayBorder.main + "!important",
            color: theme.palette.blackFont.main,
            fontWeight: theme.typography.fontWeightBold,
            "&:hover": {
                backgroundColor: theme.palette.lightgrayHover.main,
            },
        },
    },
}));

const selectionOptions = [
    { id: SelectionOption.MyTeam, display: "My Teams" },
    { id: SelectionOption.Self, display: "Me" },
    { id: SelectionOption.SpecificTeam, display: "Specific Team" },
];

interface IAssignedInteractionsProps {}

const AssignedInteractions: React.FC<IAssignedInteractionsProps> = observer(
    () => {
        const store = useStore(ManagerInteractionsStore);
        const classes = useStyles();
        const orgStore = useStore(AuthStore).orgStore;

        const {
            elementHeight: controlsHeight,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            elementWidth: controlsWidth,
            theRef: controlsRef,
        } = useResizeObserver<HTMLDivElement>({
            name: "Assigned Interactions Controls",
            debounceWait: 33,
        });

        const onViewQueues = () => {
            const whichQueues = store.teams;

            store.onViewQueues(
                <Observer>
                    {() => (
                        <div>
                            {whichQueues.map((assignment) => {
                                return (
                                    <Grid container key={assignment.id}>
                                        <Tooltip title={assignment.label}>
                                            <span style={{ width: "100%" }}>
                                                <AcxChip
                                                    style={{
                                                        maxWidth: "100%",
                                                    }}
                                                    size="small"
                                                    color="blue"
                                                    label={assignment.label}
                                                />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                );
                            })}
                        </div>
                    )}
                </Observer>,
                classes.empty,
            );
        };

        const teamSelector =
            store.selectionOption === SelectionOption.SpecificTeam ? (
                <Grid item md={4} sm={6} xs={12}>
                    <AcxSelectSingle
                        id="analyst-group-select"
                        options={store.teams}
                        onChange={store.setSelectedTeam}
                        valueField="id"
                        labelField="label"
                        defaultValue={store.selectedTeam}
                        inputLabel="Select a team to display"
                    />
                </Grid>
            ) : null;

        const selectorElement = (
            <>
                <Grid item>
                    <AcxToggleButton
                        id="interaction-filter-option"
                        exclusive
                        onChange={store.setSelectionOption}
                        spacing={3}
                        items={selectionOptions.map((op, i) => {
                            return { value: op.id, element: op.display };
                        })}
                        defaultValue={store.selectionOption}
                        inputLabel="Interactions assigned to.."
                        darkBg
                    />
                </Grid>
                {teamSelector}
            </>
        );

        const showQueueViewer =
            !store.isLoadingQueuesForTeam &&
            store.teams &&
            store.teams.length > 0 &&
            (store.selectionOption === SelectionOption.MyTeam ||
                store.selectionOption === SelectionOption.Self);

        React.useEffect(() => {
            if (orgStore && orgStore.selectedOrganization) {
                store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
            }
        }, [orgStore, orgStore?.selectedOrganization, store]);

        React.useEffect(() => {
            if (store.authStore.canUserManage("Interactions")) {
                const orgExists = selectionOptions.findIndex((val) => {
                    return val.id === SelectionOption.Org;
                });
                if (orgExists < 0) {
                    selectionOptions.insertAt(0, {
                        id: SelectionOption.Org,
                        display: "My Organization",
                    });
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store.authStore.permStore.appPermissions]);

        React.useEffect(() => {
            let columns: IColDef[] = [
                {
                    headerName: "Eval Number",
                    field: "evalNumber",
                    type: "string",
                    width: 110,
                    headerAlign: "left",
                    renderCell: (params) => {
                        const orgId = orgStore.selectedOrganization?.id;
                        const evalId = params.row.evaluationId;

                        return (
                            <Link
                                data-testid="assigned-interactions-evalulation-number-link"
                                to={Routes.makeEvaluationRoute(
                                    orgId ?? "",
                                    evalId ?? "",
                                )}
                            >
                                {params.row.evalNumber}
                            </Link>
                        );
                    },
                },
                {
                    headerName: "Name",
                    field: "name",
                    flex: 1,
                    cellClassName: "pendo-ignore",
                    type: "string",
                    valueFormatter(_, row, column) {
                        let evalName = evaluationNameFromBlobFileKey(
                            row.name as string | undefined,
                        );
                        if (evalName === "")
                            evalName = row.name?.toString() ?? "";
                        return evalName;
                    },
                    // disableClickEventBubbling: true,
                },
                {
                    headerName: "Arrived On",
                    field: "arrivedOn",
                    width: 110,
                    ...dateColumnType,
                },
                {
                    headerName: "Started On",
                    field: "startDate",
                    width: 110,
                    ...dateColumnType,
                },
                {
                    headerName: "Completed On",
                    field: "submittedDate",
                    width: 130,
                    ...dateColumnType,
                },
                {
                    headerName: "Interaction Date",
                    field: "interactionDate",
                    width: 150,
                    ...interactionDateColumnType,
                },
                {
                    headerName: "Sampled Date",
                    field: "sampledDate",
                    width: 130,
                    ...dateColumnType,
                },
                {
                    headerName: "Modified Date",
                    field: "modifiedDate",
                    width: 130,
                    ...dateColumnType,
                },
                {
                    headerName: "Agent",
                    field: "agentName",
                    width: 150,
                    type: "string",
                },
                {
                    headerName: "Call Length",
                    field: "callDurationMillis",
                    width: 120,
                    ...durationColumnType,
                },
                {
                    headerName: "Assigned To",
                    field: "assignedAnalysts",
                    width: 150,
                    type: "string",
                },
                {
                    headerName: "Status",
                    field: "evaluationStatus",
                    width: 150,
                    ...statusColumnType,
                },
                {
                    headerName: "Client Call ID",
                    field: "clientCallId",
                    type: "string",
                    width: 170,
                },
                {
                    headerName: "Agent Code",
                    field: "agentId",
                    type: "string",
                    width: 120,
                },
                {
                    headerName: "Phone Number",
                    field: "phoneNumber",
                    type: "string",
                    width: 120,
                },
                {
                    headerName: "Campaign",
                    field: "campaign",
                    type: "string",
                    width: 120,
                },
            ];

            if (store.metaLabels) {
                columns = [...columns, ...store.metaLabels];
            }

            const outstandingModulesColumn: IColDef = {
                headerName: "Outstanding Modules",
                field: "outstandingModules",
                width: 240,
                type: "string",
                renderCell: (params) => params.value as React.ReactNode,
                sortComparator: (v1, v2) =>
                    v1?.toString().localeCompare(v2?.toString() ?? "") ?? 1,
                valueGetter: (_, row) =>
                    row.evaluationStatus === 1 ? row.outstandingModules : " ",
            };

            if (store.orgSelectStore.organization?.enhancedEvalStatus) {
                if (!columns.includes(outstandingModulesColumn)) {
                    columns.insertAt(
                        columns.findIndex(
                            (col) => col.field === "evaluationStatus",
                        ) + 1,
                        outstandingModulesColumn,
                    );
                }
            } else if (columns.includes(outstandingModulesColumn)) {
                columns = columns.filter(
                    (column) => column.field !== "outstandingModules",
                );
            }

            const stats =
                store.interactionDgStore.rows.length > 0 ? (
                    <SummaryDisplay stats={store.summaryStats} />
                ) : undefined;

            const queueAssignButtons = (
                <Grid item container justifyContent="flex-end">
                    <ButtonGroup
                        color="secondary"
                        fullWidth
                        style={{
                            height: "32px",
                            fontSize: "13px",
                        }}
                        className={clsx(classes.groupRoot, classes.grouped)}
                        disableElevation
                        disabled={
                            !store.authStore.canUserEditAny([
                                "Assign Analyst",
                                "Un-Assign Analyst",
                                "Reassign Group",
                                "Un-Assign Group",
                            ]) ||
                            !store.interactionDgStore.selectedRowIds.length
                        }
                    >
                        {store.authStore.canUserEdit("Assign Analyst") && (
                            <Button
                                className={classes.btnRoot}
                                onClick={() =>
                                    store.setOpenAnalystReassign(true)
                                }
                            >
                                Assign Analyst
                            </Button>
                        )}

                        {store.authStore.canUserEdit("Un-Assign Analyst") && (
                            <Button
                                className={classes.btnRoot}
                                onClick={store.unassignAnalystDialog.open}
                            >
                                Unassign Analyst
                            </Button>
                        )}

                        {store.authStore.canUserEdit("Reassign Group") && (
                            <Button
                                className={classes.btnRoot}
                                onClick={store.openDialog}
                            >
                                Reassign Group
                            </Button>
                        )}

                        {store.authStore.canUserEdit("Un-Assign Group") && (
                            <Button
                                className={classes.btnRoot}
                                onClick={store.unAssignInteractions}
                            >
                                Unassign Group
                            </Button>
                        )}
                    </ButtonGroup>
                </Grid>
            );

            const dateRangePicker = (
                <Grid item container justifyContent={"flex-end"}>
                    <Observer>
                        {() => (
                            <AcxDateRangeInput
                                datePickerStore={store.assignedDatePickerStore}
                                displayDateType
                                dateReferenceOptions={Object.values(
                                    InteractionDateReferenceOption,
                                )}
                                defaultStartDate={
                                    store.assignedDatePickerStore.beginDate
                                }
                                defaultEndDate={
                                    store.assignedDatePickerStore.endDate
                                }
                                onSelect={(start: Moment, end: Moment) => {
                                    store.assignedDatePickerStore.setBeginDate(
                                        start,
                                    );
                                    store.assignedDatePickerStore.setEndDate(
                                        end,
                                    );
                                }}
                            />
                        )}
                    </Observer>
                </Grid>
            );

            const refreshTable = (
                <Observer>
                    {() => (
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            style={{ paddingRight: "0.5rem" }}
                        >
                            <AcxButton
                                disabled={store.getTaskLoading(LOAD_ASSIGNED)}
                                style={{
                                    height: "32px",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                }}
                                tooltip="Refresh Table"
                                color="primary"
                                onClick={() => {
                                    store.reloadInteractions("Assigned");
                                }}
                            >
                                <CachedIcon />
                            </AcxButton>
                        </Grid>
                    )}
                </Observer>
            );

            const showMachineEvalsSwitch = (
                <FormControlLabel
                    control={
                        <AcxSwitch
                            onChange={store.toggleShowMachineEvals}
                            checked={store.showMachineEvals}
                        />
                    }
                    label="Show AI Scored Evaluations"
                />
            );

            const controls: CustomControlItem[] = [
                {
                    controlElement: dateRangePicker,
                },
                {
                    controlElement: refreshTable,
                },
                {
                    controlElement: showMachineEvalsSwitch,
                },
            ];

            if (
                store.authStore.canUserEditAny([
                    "Assign Analyst",
                    "Un-Assign Analyst",
                    "Reassign Group",
                    "Un-Assign Group",
                ])
            ) {
                controls.push({
                    controlElement: queueAssignButtons,
                    xs: 10,
                    sm: 8,
                    md: 8,
                    lg: 5,
                    xl: 3,
                });
            }
            store.interactionDgStore.setColumns(columns);
            store.interactionDgStore.controls = controls;
            store.interactionDgStore.density = "compact";
            store.interactionDgStore.preHeader = stats;
            store.interactionDgStore.checkboxSelection =
                store.authStore.canUserEditAny([
                    "Assign Analyst",
                    "Un-Assign Analyst",
                    "Reassign Group",
                    "Un-Assign Group",
                ]);
            store.interactionDgStore.headerColumnSpan = 12;
            store.interactionDgStore.controlsColumnSpan = 6;
            store.interactionDgStore.controlsJustifyProperty = "flex-start";
            store.interactionDgStore.controlsColumnStyle = {
                marginLeft: "0px",
                marginBottom: "0.25rem",
            };

            store.interactionDgStore.removeHeight = "80px";
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            store,
            store.summaryStats,
            store.unAssignInteractions,
            store.authStore.permStore.appPermissions,
            store.interactionDgStore.selectedRows.length,
            classes.tableButton,
            store.metaLabels,
            store.assignedDatePickerStore.referenceOption,
            orgStore,
        ]);

        return (
            <>
                <div
                    ref={controlsRef}
                    style={{ paddingLeft: "16px", paddingRight: "16px" }}
                >
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        wrap={"nowrap"}
                    >
                        <Grid
                            key="Hier-selector-elements"
                            item
                            xs={10}
                            container
                            spacing={3}
                            style={{ paddingTop: "20px" }}
                        >
                            {store.orgSelectStore.orgId && selectorElement}
                        </Grid>

                        <Grid
                            item
                            xs={2}
                            container
                            alignItems={"flex-start"}
                            justifyContent={"flex-end"}
                            style={{ paddingTop: "10px" }}
                        >
                            {showQueueViewer && (
                                <AcxHierarchyDetailsFab
                                    orgId={orgStore.selectedOrganization?.id}
                                    userId={store.user?.profile?.sub}
                                    handleOnClick={onViewQueues}
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>

                <div
                    style={{
                        height: `calc(100% - ${controlsHeight ?? 0}px - 5rem)`,
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingBottom: "16px",
                    }}
                >
                    <AcxDataGrid dataGridStore={store.interactionDgStore} />
                </div>

                <AssignAnalystDialog
                    open={store.openAnalystReassign}
                    orgId={store.orgSelectStore.orgId}
                    analystId={store.user?.profile.sub}
                    onSave={store.assignToAnalyst}
                    onClose={() => store.setOpenAnalystReassign(false)}
                    isLoading={store.getTaskLoading("Assign To Analyst")}
                />

                <UnAssignAnalystDialog
                    isOpen={store.unassignAnalystDialog.isOpen}
                    onClose={store.unassignAnalystDialog.close}
                    onSubmitHandler={store.unAssignAnalyst}
                    isLoading={store.getTaskLoading("Unassign Analyst")}
                />
            </>
        );
    },
);

export default AssignedInteractions;
