import React from "react";
import AcxDialog from "./Dialog/AcxDialog";
import AcxButton from "./AcxButton";
import copy from "copy-to-clipboard";
import { useStore } from "../../utils/useStore";
import MessageStore from "../ManagerInteractions/Stores/MessageStore";
import { observer } from "mobx-react";
import { AuthStore } from "stores/AuthStore";

interface Props {
    title?: string;
    sqlStatements: (string | undefined)[];
    isModalOpen: boolean;
    onClose: () => void;
}

export const AcxViewSqlDialog: React.FC<Props> = observer(
    ({ title, sqlStatements, ...props }) => {
        const messageStore = useStore(MessageStore);
        const authStore = useStore(AuthStore);

        // If user is not ultra, and no sql statements are present, don't render
        if (!authStore.isUserUltra() || sqlStatements.length < 1) {
            return null;
        }

        return (
            <AcxDialog
                isOpen={!!props.isModalOpen}
                onClose={props.onClose}
                title={title || "SQL Queries"}
                maxWidth="xl"
                dialogContentChildren={sqlStatements
                    .filter((s) => !!s)
                    .map((s, i) => (
                        <React.Fragment key={i}>
                            <pre>{s}</pre>
                            <AcxButton
                                onClick={() => {
                                    copy(s!);
                                    messageStore.logInfo("Copied to clipboard");
                                }}
                            >
                                Copy to clipboard
                            </AcxButton>
                        </React.Fragment>
                    ))}
            />
        );
    },
);
