import { Box, Grid } from "@mui/material";
import AcxChip from "components/UI/AcxChip";
import moment from "moment";
import React from "react";
import theme from "Theme/AppTheme";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";

type Props = {
    inputValue: number;
    isNew?: boolean;
    isToZero?: boolean;
    isNegativeRed?: boolean;
    isGrayOnly?: boolean;
    showMinutes?: boolean;
    showGrowthSubtitle?: boolean;
};

export const SignalsTrendingIndicator = (props: Props) => {
    const {
        inputValue,
        isNew,
        isToZero,
        isNegativeRed,
        isGrayOnly,
        showMinutes,
        showGrowthSubtitle,
    } = props;
    let formattedValue = inputValue.toString();
    let chipColor = isGrayOnly ? "gray" : isNegativeRed ? "darkGreen" : "red";
    const trendUpIcon = (
        <TrendingUpRoundedIcon
            style={{
                color: isGrayOnly
                    ? theme.palette.gray.main
                    : isNegativeRed
                    ? theme.palette.success.dark
                    : theme.palette.error[700],
                marginRight: theme.spacing(1),
            }}
        />
    );
    const trendDownIcon = (
        <TrendingDownRoundedIcon
            style={{
                color: isGrayOnly
                    ? theme.palette.gray.main
                    : isNegativeRed
                    ? theme.palette.error[700]
                    : theme.palette.success.dark,
                marginRight: theme.spacing(1),
            }}
        />
    );

    const trendFlatIcon = (
        <TrendingFlatRoundedIcon
            style={{
                color: theme.palette.gray.main,
                marginRight: theme.spacing(1),
            }}
        />
    );

    let icon = trendUpIcon;

    if (inputValue < 0) {
        formattedValue = Math.abs(inputValue).toString();
        chipColor = isGrayOnly ? "gray" : isNegativeRed ? "red" : "darkGreen";
        icon = trendDownIcon;
    } else if (inputValue === 0) {
        chipColor = "gray";
        icon = trendFlatIcon;
    }

    if (showMinutes) {
        const seconds = Math.abs(inputValue) / 1000;

        const duration = moment.duration(seconds, "seconds");

        const formatted: string[] = [];
        if (duration.hours()) formatted.push(`${duration.hours()}h`);
        if (duration.minutes()) formatted.push(`${duration.minutes()}m`);
        if (duration.seconds()) formatted.push(`${duration.seconds()}s`);

        if (seconds < 1) {
            formattedValue = "0";
            icon = trendFlatIcon;
            chipColor = "gray";
        } else {
            formattedValue = formatted.join(" ");
        }
    }

    return (
        <Grid
            item
            container
            style={{ height: "100%" }}
            alignItems={"center"}
            justifyContent={"flex-end"}
        >
            <AcxChip
                size="small"
                color={chipColor as any}
                label={
                    <Grid
                        item
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {icon}
                        {formattedValue}
                        {showMinutes ? "" : "%"}
                        {showGrowthSubtitle ? (
                            <Box
                                fontSize={"8px"}
                                fontWeight={600}
                                marginLeft={theme.spacing(0.5)}
                            >
                                GROWTH
                            </Box>
                        ) : (
                            ""
                        )}
                    </Grid>
                }
            />
            {isNew && (
                <AcxChip
                    customTooltip={
                        "This is a new topic this period, as reflected by the percent change."
                    }
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                    size="small"
                    color="blue"
                    label="New"
                />
            )}
            {isToZero && (
                <AcxChip
                    customTooltip="This topic has gone to zero during this period, as reflected by the percent change."
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                    size="small"
                    color="gray"
                    label="Zero"
                />
            )}
        </Grid>
    );
};
