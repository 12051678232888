import { Grid, useTheme } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import SignalsReportStore from "../Store/SignalsReportStore";

const newDashVisItems = [
    { id: "signals-dashboard-only-me", value: "User", element: "Only Me" },
    {
        id: "signals-dashboard-my-organization",
        value: "Organization",
        element: "My Organization",
    },
];

interface SignalsCreateDashboardFormProps {
    disabled?: boolean;
}

export const SignalsCreateDashboardForm = observer(
    (props: SignalsCreateDashboardFormProps) => {
        const signalsReportStore = useStore(SignalsReportStore);
        const theme = useTheme();

        return (
            <Grid container style={{ width: "30vw" }} spacing={1}>
                <Grid item xs={12}>
                    <AcxMainTextField
                        id="new-dashboard-title-input"
                        labelText="Dashboard Name"
                        value={signalsReportStore.newDashboardTitle}
                        onChange={(e) =>
                            (signalsReportStore.newDashboardTitle =
                                e.target.value)
                        }
                        required={!props.disabled}
                        isDisabled={props.disabled}
                        textareaRootStyles={{
                            backgroundColor: props.disabled
                                ? theme.palette.lightgray.background
                                : undefined,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AcxToggleButton
                        id="signals-new-dashboard-visibility"
                        items={newDashVisItems}
                        defaultValue={
                            newDashVisItems.find(
                                (i) =>
                                    i.value ===
                                    signalsReportStore.newDashboardVis,
                            )?.value
                        }
                        inputLabel="Dashboard Visibility"
                        onChange={(item) => {
                            if (!item) return;
                            signalsReportStore.newDashboardVis = item;
                        }}
                        exclusive
                        disabled={props.disabled}
                    />
                </Grid>
            </Grid>
        );
    },
);
