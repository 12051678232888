import React from "react";
import OrganizationAnalystGroupsList from "./OrganizationAnalystGroupsList";

type Props = {
    id: string;
};

const OrganizationAnalystGroups: React.FC<Props> = ({ id }) => {
    return <OrganizationAnalystGroupsList orgId={id} />;
};

export default OrganizationAnalystGroups;
