import { Box, Checkbox, IconButton } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Clear";
import React, { useState, useRef } from "react";
import theme from "Theme/AppTheme";
import { SourceFieldType } from "../OrganizationDataSourcesStore";

type SourceFieldProps = SourceFieldType & {
    onRemove: () => void;
    onChange: (field: SourceFieldType) => void;
};

const SourceField: React.FC<SourceFieldProps> = ({
    onRemove,
    onChange,
    path,
    alias,
    extractionPattern,
    isRequired,
    id,
}) => {
    const [showPattern, setShowPattern] = useState(extractionPattern !== ""); // show extraction pattern input if extraction pattern exists
    const [showDelete, setShowDelete] = useState(false);
    const sourceFieldRef = useRef<HTMLLIElement>(null);

    const handleChange = (value: string | boolean, field: string) => {
        onChange({
            path,
            alias,
            extractionPattern,
            isRequired,
            id,
            [field]: value,
        });
    };

    return (
        <Box
            ref={sourceFieldRef}
            sx={{
                borderRadius: 2,
                padding: theme.spacing(1, 0.5),
                background: theme.palette.white.main,
                display: "flex",
            }}
            component="li"
            onMouseEnter={() => setShowDelete(true)}
            onFocus={() => setShowDelete(true)}
            onBlur={() => {
                setTimeout(() => {
                    // check if the element is within the current box before hiding the delete button
                    // Useful for keyboard navigation
                    if (
                        !sourceFieldRef.current?.contains(
                            document.activeElement,
                        ) &&
                        !sourceFieldRef.current?.matches(":hover")
                    ) {
                        setShowDelete(false);
                    }
                }, 100);
            }}
            onMouseLeave={() => setShowDelete(false)}
        >
            <Checkbox
                size="small"
                checked={isRequired}
                onChange={(e) => handleChange(e.target.checked, "isRequired")}
                sx={{
                    padding: theme.spacing(0.5),
                    margin: theme.spacing(0.5, 1.5, 0, 0),
                    height: "fit-content",
                    "svg.MuiSvgIcon-root": {
                        width: "20px",
                        height: "20px",
                    },
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: theme.spacing(1),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <AcxMainTextField
                        id={`source-field-path-${id}`}
                        type="text"
                        placeholderText="Field Path"
                        value={path}
                        onChange={(e) => handleChange(e.target.value, "path")}
                        containerStyle={{
                            flex: 5,
                            marginRight: theme.spacing(3),
                        }}
                        fullWidth
                    />
                    <AcxMainTextField
                        id={`source-field-alias-${id}`}
                        type="text"
                        placeholderText="Source Field Alias"
                        value={alias}
                        onChange={(e) => handleChange(e.target.value, "alias")}
                        containerStyle={{
                            flex: 2,
                            marginRight: theme.spacing(0.5),
                        }}
                        fullWidth
                    />
                    <IconButton
                        size="small"
                        onClick={() => {
                            setShowPattern(true);
                        }}
                        sx={{
                            padding: theme.spacing(0.5),
                            height: "fit-content",
                            visibility: showPattern ? "hidden" : "visible",
                        }}
                    >
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                {showPattern && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <AcxMainTextField
                            id={`source-field-pattern-${id}`}
                            type="text"
                            placeholderText="Enter Regex extraction pattern"
                            value={extractionPattern}
                            onChange={(e) =>
                                handleChange(
                                    e.target.value,
                                    "extractionPattern",
                                )
                            }
                            containerStyle={{
                                flex: 2,
                                marginRight: theme.spacing(0.5),
                            }}
                            fullWidth
                        />
                        <IconButton
                            size="small"
                            onClick={() => {
                                setShowPattern(false);
                            }}
                            sx={{
                                padding: theme.spacing(0.5),
                                height: "fit-content",
                            }}
                        >
                            <MinusIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <IconButton
                size="small"
                sx={{
                    padding: theme.spacing(0.25),
                    height: "fit-content",
                    background: theme.palette.gray[400],
                    color: theme.palette.white.main,
                    margin: theme.spacing(1, 0.5),
                    visibility: showDelete ? "visible" : "hidden",
                    ":hover": {
                        background: theme.palette.gray[500],
                    },
                }}
                onClick={() => onRemove()}
            >
                <DeleteIcon fontSize="inherit" />
            </IconButton>
        </Box>
    );
};

export default SourceField;
