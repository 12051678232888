import {
    GridComparatorFn,
    GridRenderCellParams,
    GridSortCellParams,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";

export const DateFormatterIgnoreTime = (param: GridRenderCellParams) => {
    const momentDate = parseFromISO(param.value, true);

    return param.value ? standardDateFormat(momentDate) : "";
};

export const DateFormatterIgnoreTimeGetter = (_, row, column) => {
    const value = row[column.field];

    if (!value) {
        return "";
    }

    return value instanceof Date ? value : new Date(value);
};

export const DateComparator: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = _.get(row1, param1.field)?.toString();
    const p2 = _.get(row2, param2.field)?.toString();

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};
