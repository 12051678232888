import {
    Divider,
    Typography,
    FormControlLabel,
    Switch,
    Container,
    Box,
    FormGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import theme from "Theme/AppTheme";
import SourceField from "./SourceField";
import AcxButton from "components/UI/AcxButton";
import { v4 as uuidv4 } from "uuid";
import { OrganizationDataSourcesStore } from "../OrganizationDataSourcesStore";
import { SourceFieldType } from "../OrganizationDataSourcesStore";
import { observer } from "mobx-react";

type DataSourceSetupProps = {
    store: OrganizationDataSourcesStore;
};

const SourceConfiguration: React.FC<DataSourceSetupProps> = observer(
    ({ store }) => {
        const {
            sourceFields,
            setSourceFields,
            setShouldConcatenateFields,
            setShouldApplyConditions,
            shouldApplyConditions,
            shouldConcatenateFields,
        } = store;

        const handleAddField = () => {
            const updatedSourceFields = [
                ...sourceFields,
                {
                    isRequired: true,
                    path: "",
                    alias: "",
                    extractionPattern: "",
                    id: uuidv4(),
                },
            ];
            setSourceFields(updatedSourceFields);
        };

        const handleRemoveField = (id: string) => {
            const updatedSourceFields = sourceFields.filter(
                (field) => field.id !== id,
            );
            setSourceFields(updatedSourceFields);
        };

        const handleChange = (id: string, field: SourceFieldType) => {
            const updatedSourceFields = sourceFields.map((f) =>
                f.id === id ? field : f,
            );
            setSourceFields(updatedSourceFields);
        };

        return (
            <Container
                maxWidth="xl"
                sx={{
                    "&.MuiContainer-root": {
                        padding: theme.spacing(2, 0),
                    },
                }}
            >
                <Container
                    maxWidth="xl"
                    sx={{
                        padding: theme.spacing(2, 0),
                        borderRadius: 2,
                        background: theme.palette.white.main,
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07);",
                    }}
                >
                    <Typography
                        component={"h2"}
                        sx={{
                            lineHeight: "1.5",
                            fontWeight: 600,
                            fontSize: "16px",
                        }}
                    >
                        Configure Source Fields
                    </Typography>
                    <Divider
                        sx={{
                            margin: theme.spacing(1, 0, 2),
                        }}
                    />
                    <Box
                        sx={{
                            padding: theme.spacing(2),
                            background: theme.palette.gray[100],
                            borderRadius: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                Req
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                Source Fields:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                    background: theme.palette.gray[300],
                                    borderRadius: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {sourceFields.length}
                            </Typography>
                        </Box>
                        <Box
                            component="ul"
                            sx={{
                                display: "flex",
                                listStyle: "none",
                                flexDirection: "column",
                                paddingInlineStart: 0,
                                gap: theme.spacing(2),
                            }}
                        >
                            {sourceFields.map((field) => (
                                <SourceField
                                    key={field.id}
                                    id={field.id}
                                    path={field.path}
                                    alias={field.alias}
                                    isRequired={field.isRequired}
                                    extractionPattern={field.extractionPattern}
                                    onRemove={() => handleRemoveField(field.id)}
                                    onChange={(_field) =>
                                        handleChange(field.id, _field)
                                    }
                                />
                            ))}
                        </Box>
                        <AcxButton
                            startIcon={<AddIcon />}
                            color="white"
                            onClick={handleAddField}
                            sx={{
                                border: `1px solid ${theme.palette.lightgrayBorder.main} `,
                                background: theme.palette.white.main,
                                color: theme.palette.blue.main,
                            }}
                        >
                            Add Field
                        </AcxButton>
                    </Box>
                </Container>
                <Box
                    sx={{
                        display: "flex",
                        gap: theme.spacing(3),
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: theme.palette.gray[500],
                        }}
                    >
                        Advanced Options:
                    </Typography>
                    <FormGroup
                        sx={{
                            gap: theme.spacing(3),
                            flexDirection: "row",
                            ".MuiSwitch-switchBase.Mui-checked": {
                                // reduce translate since switch is slimmer
                                transform: "translateX(12px)",
                            },
                        }}
                    >
                        <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                                <Switch
                                    sx={{ margin: theme.spacing(0, 1, 0, 0) }}
                                    checked={shouldApplyConditions}
                                    onChange={() =>
                                        setShouldApplyConditions(
                                            !shouldApplyConditions,
                                        )
                                    }
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: theme.palette.gray[800],
                                    }}
                                >
                                    Apply Conditions to Extraction
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                                <Switch
                                    sx={{ margin: theme.spacing(0, 1, 0, 0) }}
                                    checked={shouldConcatenateFields}
                                    onChange={() =>
                                        setShouldConcatenateFields(
                                            !shouldConcatenateFields,
                                        )
                                    }
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: theme.palette.gray[800],
                                    }}
                                >
                                    Concatenate Fields
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Box>
            </Container>
        );
    },
);

export default SourceConfiguration;
