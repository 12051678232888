import React from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import { observer } from "mobx-react";
import AcxButton from "components/UI/AcxButton";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { Grid } from "@mui/material";
import theme from "Theme/AppTheme";
import WFRResultBanner from "./WFRResultBanner";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";

const useStyles = makeStyles<Theme>((theme) => ({
    containerMargin: { marginTop: theme.spacing(4) },
}));

interface WFRScoreEscalationAdvanceCardProps {
    level: number;
    instanceId: string;
    module: EvalLicensedModule | null;
    onConfirm?: () => void;
    buttonDisabled?: boolean;
    loading?: boolean;
}

const WFRScoreEscalationAdvanceCard: React.FC<WFRScoreEscalationAdvanceCardProps> =
    observer((props) => {
        const classes = useStyles();
        const store = useStore(WorkflowReviewStore);

        const viewingCurrentLevel =
            store.workflowReviewerInfo?.workflowInfo.currentLevel ===
            store.stepperLevel;

        return (
            <Box className={classes.containerMargin}>
                <Card>
                    {(store.workflowReviewerInfo?.workflowInfo.status ===
                        WorkflowStatus.Closed ||
                        store.currentLevel?.result) && (
                        <CardHeader
                            sx={{
                                margin: theme.spacing(2),
                                padding: 0,
                                marginBottom: 0,
                                overflow: "visible",
                            }}
                            title={
                                store.currentLevel &&
                                !(
                                    store.workflowReviewerInfo?.workflowType ===
                                        WorkflowConditionType.ScoreEscalation &&
                                    store.workflowReviewerInfo.workflowInfo
                                        .status === WorkflowStatus.Closed &&
                                    store.currentLevel.isBossLevel
                                ) && (
                                    <WFRResultBanner
                                        completedBy={
                                            store.currentLevel.completedBy
                                        }
                                        result={store.currentLevel.result}
                                        verifiedDate={
                                            store.currentLevel.completedOn
                                        }
                                    />
                                )
                            }
                        />
                    )}
                    <CardHeader
                        title="Workflow Review"
                        subheader={
                            <>
                                <b>{`Instructions: `}</b>
                                <span>
                                    Please review the answers in the workflow
                                    trigger section before submitting.
                                </span>
                            </>
                        }
                    />
                    <CardContent>
                        <Grid container direction="row-reverse">
                            <Grid item xs={3}>
                                {store.currentLevelState !== "boss" && (
                                    <AcxButton
                                        fullWidth
                                        color="primary"
                                        onClick={props.onConfirm}
                                        disabled={props.buttonDisabled}
                                        loading={props.loading}
                                    >
                                        {viewingCurrentLevel
                                            ? "Submit"
                                            : "Level Completed"}
                                    </AcxButton>
                                )}
                                {store.currentLevelState === "boss" && (
                                    <AcxButton
                                        fullWidth
                                        color="primary"
                                        disabled={props.buttonDisabled}
                                        onClick={props.onConfirm}
                                    >
                                        Complete Workflow
                                    </AcxButton>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    });

export default WFRScoreEscalationAdvanceCard;
