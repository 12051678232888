import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Grid, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxCard from "components/UI/Cards/AcxCard";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { EddyPresence, SignalsDomain } from "../Models/SignalsModels";
import SignalsReportStore, {
    AverageCallDurationCardLoading,
    ConversationsWithAnEddyLoading,
    TotalConversationsCardLoading,
} from "../Store/SignalsReportStore";
import { SignalsTrendingIndicator } from "./SignalsTrendingIndicator";

const StyledDiv = styled("div")(({ theme }) => ({
    display: "grid",
    paddingInline: theme.spacing(2),
    columnGap: theme.spacing(2.75),
    rowGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("lg")]: {
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
    },
    zIndex: 1000,
    input: {
        position: "absolute",
        top: "2px",
    },
}));

const cardRootStyle: React.CSSProperties = {
    maxWidth: "none",
    borderRadius: theme.spacing(1),
    boxShadow: `0px 1px 8px 0px ${alpha(theme.palette.black.main, 0.05)}`,
};

const avgCallDurationOptions = [
    {
        label: "with an Eddy",
        value: EddyPresence.WithEddy,
    },
    {
        label: "without an Eddy",
        value: EddyPresence.WithoutEddy,
    },
    {
        label: "All Conversations",
        value: EddyPresence.AllConversations,
    },
];

type Props = { domainName: SignalsDomain };

const Title = ({
    children,
    marginRight = "0",
}: {
    children: React.ReactNode;
    marginRight?: string;
}) => (
    <Typography
        role="heading"
        fontWeight="600"
        fontSize="16px"
        color={theme.palette.gray[500]}
        lineHeight="1.5"
        marginRight={marginRight}
    >
        {children}
    </Typography>
);

const Content = ({ children }: { children: React.ReactNode }) => (
    <Typography
        fontWeight="600"
        fontSize="20px"
        lineHeight="32px"
        color={theme.palette.gray[600]}
    >
        {children}
    </Typography>
);

const SignalsCards = observer((props: Props) => {
    const store = useStore(SignalsReportStore);

    const isTotalConversationsLoading = store.getTaskLoading(
        TotalConversationsCardLoading,
    );

    const isAvgCallDurationLoading = store.getTaskLoading(
        AverageCallDurationCardLoading,
    );
    const isConversationsWithEddyLoading = store.getTaskLoading(
        ConversationsWithAnEddyLoading,
    );

    const mainContentStyle = {
        justifyContent: "start",
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
    };

    const titleColor = theme.palette.gray[500];

    return (
        <StyledDiv>
            <AcxCard
                loading={isTotalConversationsLoading}
                loadingSize={24}
                title={<Title>Total Conversations</Title>}
                rootStyle={cardRootStyle}
                mainContentStyle={mainContentStyle}
                headerStyle={{ paddingBottom: 0 }}
                mainContent={
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        data-testid="small-card-trend-indicator-1"
                    >
                        <Content>
                            {store.getTotalConversationsFormatted()}
                        </Content>
                        <Grid item>
                            <SignalsTrendingIndicator
                                inputValue={
                                    store.totalConversationsPercentChange ?? 0
                                }
                                isGrayOnly
                            />
                        </Grid>
                    </Grid>
                }
            />
            <AcxCard
                loading={isAvgCallDurationLoading}
                loadingSize={24}
                headerStyle={{ paddingBottom: 0 }}
                title={
                    <Grid container columnSpacing={0.25} alignItems="center">
                        <Grid item>
                            <Title marginRight={theme.spacing(0.5)}>
                                Avg Call Duration
                            </Title>
                        </Grid>
                        <Grid
                            item
                            style={{
                                position: "relative",
                                paddingBottom: "4px",
                            }}
                        >
                            <AcxSelectSingle
                                id="signals-average-call-duration-select"
                                isDisabled={isAvgCallDurationLoading}
                                dropdownIndicator={
                                    <ExpandMore htmlColor={titleColor} />
                                }
                                customStyle={{
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: "20px",
                                        paddingInline: "0px",
                                        display: "flex",
                                        alignItems: "start",
                                        overflow: "visible",
                                        top: "-4px", // add space between input and border part 1/2
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        height: "16px",
                                        width: "16px",
                                        justifyContent: "center",
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderRadius: "0px",
                                        margin: "0",
                                        padding: "0px",
                                        width: "164px",
                                        height: "20px",
                                        top: "6px", // add space between input and border part 2/2
                                        color: titleColor,
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        // react-select positions the single value element absolutely.
                                        // It uses a combination of transform with translateY(50%) and top (50%) css properties
                                        // to get it into the right place. For this implementation of the select it was
                                        // becoming unpredictable where the value would be positioned. Therefore I opted to make
                                        // the value container a flex container and align items start then reset the transorm (Y pos)
                                        // and top to 0% here. I have found this to be more reliable but can break during hot reload sometimes.
                                        transform: "translateY(0%)",
                                        top: "0%",
                                        fontSize: theme.typography.h5.fontSize,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        color: titleColor,
                                        margin: "0px",
                                    }),
                                }}
                                options={avgCallDurationOptions}
                                valueField="value"
                                labelField="label"
                                defaultValue={avgCallDurationOptions.find(
                                    (option) =>
                                        option.value ===
                                        store.averageCallDurationEddyFilter,
                                )}
                                onChange={(filter) => {
                                    if (
                                        filter.value ===
                                        store.averageCallDurationEddyFilter
                                    )
                                        return;

                                    store.setAvgCallDurationFilter(
                                        filter.value,
                                    );
                                    store.getAvgCallDuration(filter.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                rootStyle={cardRootStyle}
                mainContentStyle={mainContentStyle}
                mainContent={
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        data-testid="small-card-trend-indicator-2"
                    >
                        <Content>{store.getAvgCallDurationFormatted()}</Content>
                        <Grid item>
                            <SignalsTrendingIndicator
                                inputValue={
                                    store.averageCallDurationChange ?? 0
                                }
                                showMinutes
                            />
                        </Grid>
                    </Grid>
                }
            />

            <AcxCard
                loading={isConversationsWithEddyLoading}
                loadingSize={24}
                headerStyle={{ paddingBottom: 0 }}
                title={<Title>Conversations with an Eddy</Title>}
                rootStyle={cardRootStyle}
                mainContentStyle={mainContentStyle}
                mainContent={
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        data-testid="small-card-trend-indicator-3"
                    >
                        <Content>{store.getEddyPercentFormatted()}</Content>
                        <Grid item>
                            <SignalsTrendingIndicator
                                inputValue={
                                    store.conversationsWithAnEddyData
                                        ?.percentChange ?? 0
                                }
                            />
                        </Grid>
                    </Grid>
                }
            />
        </StyledDiv>
    );
});

export default SignalsCards;
