import { Grid, Typography } from "@mui/material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import copy from "copy-to-clipboard";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router";
import { useStore } from "utils/useStore";
import { ActionTypeToComponent } from "../Actions/Actions";
import { AgentAvatar } from "../Branding/AgentAvatar";
import { UserAvatar } from "../Branding/UserAvatar";
import Message from "../Messages/Message";
import { AgentState } from "../Models/AgentState";
import { MessageAuthor } from "../Models/MessageAuthor";
import { IThreadMessageGroup } from "../Models/ThreadMessageGroup";
import AgentChatStore from "../Stores/AgentChatStore";
import { Suggestion } from "../Suggestions/Suggestion";
import { MessageGroupActions } from "./MessageGroupActions";

type ThreadMessageGroupProps = {
    isMostRecent: boolean;
    cancelledStreaming: boolean;
} & IThreadMessageGroup;

export const MessageGroup = observer(
    ({
        author,
        transientMessage,
        suggestions,
        actions,
        isMostRecent,
        cancelledStreaming,
        ...props
    }: React.ComponentPropsWithoutRef<typeof Grid> &
        ThreadMessageGroupProps) => {
        const store = useStore(AgentChatStore);
        const messageStore = useStore(MessageStore);
        const navigate = useNavigate();

        const isUserMessage = author === MessageAuthor.User;
        const isAgentMessage = author === MessageAuthor.Agent;

        function copyToClipboard() {
            let content = props.messages
                .map((message) => message.toString())
                .join("\n");

            if (transientMessage) {
                content += `\n${transientMessage.toString()}`;
            }

            copy(content);
            messageStore.logInfo("Content copied to clipboard");
        }

        return (
            <Grid
                container
                item
                direction="column"
                flexWrap="nowrap"
                gap={1}
                alignSelf={isUserMessage ? "self-end" : "self-start"}
                width="300px"
                {...props}
            >
                <Grid
                    container
                    item
                    alignItems="flex-end"
                    flexWrap="nowrap"
                    gap={1}
                >
                    {isAgentMessage && (
                        <Grid item>
                            <AgentAvatar />
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        direction="column"
                        rowGap={1}
                        flexWrap="nowrap"
                        alignItems={isUserMessage ? "flex-end" : "flex-start"}
                    >
                        {props.messages.map((message, index) => (
                            <Message
                                key={message.id}
                                author={author}
                                {...message}
                            />
                        ))}
                        {isMostRecent && transientMessage && (
                            <Message
                                key={transientMessage.id}
                                author={author}
                                {...transientMessage}
                            />
                        )}
                    </Grid>
                    {isUserMessage && (
                        <Grid item>
                            <UserAvatar />
                        </Grid>
                    )}
                </Grid>
                {cancelledStreaming && (
                    <Grid
                        className={"pendo-ignore"}
                        container
                        item
                        alignItems="flex-end"
                        flexWrap="nowrap"
                        gap={1}
                        direction={"row"}
                    >
                        <Grid item width="28px"></Grid>
                        <Grid item>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.gray[600],
                                    fontSize: "13px",
                                    fontStyle: "italic",
                                    lineHeight: "20px",
                                    paddingLeft: theme.spacing(2),
                                })}
                            >
                                You cancelled the response
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!cancelledStreaming &&
                    isMostRecent &&
                    !transientMessage &&
                    isAgentMessage &&
                    store.state === AgentState.Idle && (
                        <MessageGroupActions onCopy={copyToClipboard} />
                    )}
                {suggestions && isMostRecent && (
                    <Grid
                        className={"pendo-ignore"}
                        container
                        item
                        alignItems="flex-end"
                        flexWrap="nowrap"
                        gap={1}
                        direction={isAgentMessage ? "row" : "row-reverse"}
                    >
                        <Grid item width="28px"></Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            gap={1}
                            flexWrap="nowrap"
                            alignItems={
                                isUserMessage ? "flex-end" : "flex-start"
                            }
                        >
                            {suggestions.map((suggestion) => (
                                <Grid item key={suggestion.content}>
                                    <Suggestion
                                        onClick={() =>
                                            store
                                                .handleSuggestion(suggestion)
                                                .then(() => {
                                                    if (
                                                        suggestion.navigationURL
                                                    ) {
                                                        navigate(
                                                            suggestion.navigationURL,
                                                        );
                                                    }
                                                })
                                        }
                                    >
                                        {suggestion.content}
                                    </Suggestion>
                                </Grid>
                            ))}
                            {actions &&
                                actions.entries().map(([type, args]) => {
                                    const Component =
                                        ActionTypeToComponent[type];
                                    if (!Component) return null;
                                    return (
                                        <Component
                                            args={args}
                                            remove={() => actions.delete(type)}
                                        />
                                    );
                                })}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    },
);
