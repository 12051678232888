import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import AgentChatStore from "../Stores/AgentChatStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { IThreadMessageGroup } from "./ThreadMessageGroup";
import { ThreadMessage } from "./ThreadMessage";
import { AvaFilters, avaFiltersToApplicationFilters } from "../AgentUtils";

export type ThreadSuggestionAction = (
    store: AgentChatStore,
    suggestion: ThreadSuggestion,
) => void | Promise<void>;

export interface ThreadSuggestion {
    content: string;
    llmInput?: string;
    action: ThreadSuggestionAction;
    serverAction?:
        | LLMInputServerAction
        | GetAdditionalInsightsFromConversationsServerAction;
    navigationURL?: string;
    doNotSendToLlm?: boolean;
}

export interface ServerAction {
    type: string;
}

export interface LLMInputServerAction extends ServerAction {
    type: "LLMInput";
    arguments: {
        input: string;
    };
}

export interface GetAdditionalInsightsFromConversationsServerAction
    extends ServerAction {
    type: "GetAdditionalInsightsFromConversations";
    arguments: {
        topic: string;
        conversationIds: string[];
    };
}

export type ThreadServerSuggestionActionType = "navigate" | "build_montage";
export type ThreadServerSuggestionNavigationActionDomain = "conversations";

interface ThreadServerSuggestionActionBase {
    type: ThreadServerSuggestionActionType;
    data?: unknown;
}

interface ThreadServerSuggestionNavigationAction
    extends ThreadServerSuggestionActionBase {
    type: "navigate";
    domain: ThreadServerSuggestionNavigationActionDomain;
    data: unknown;
}

export interface BuildMontageFromClipsData {
    clipIds: string[];
    audioSoundClipsCount?: number;
    minClipDate: string;
    maxClipDate: string;
}

interface ThreadServerSuggestionBuildMontageAction
    extends ThreadServerSuggestionActionBase {
    type: "build_montage";
    data: BuildMontageFromClipsData;
}

export interface ThreadServerSuggstionGetDeeperInsightsAction {
    type: "get_deeper_insights_on_conversations";
    data: {
        topic: string;
        conversationIds: string[];
    };
}

export interface ThreadServerSuggestionGetDeeperInsightsActionData {
    topic: string;
    conversationIds: string[];
}

export type ThreadServerSuggestionAction =
    | ThreadServerSuggestionNavigationAction
    | ThreadServerSuggestionBuildMontageAction
    | ThreadServerSuggstionGetDeeperInsightsAction;

/**
 * Represents a suggestion generated by the agent to take a specific, predefined action
 */
export interface ThreadServerSuggestion {
    content: string;
    action?: ThreadServerSuggestionAction;
}

export function fromServerSuggestion(
    serverSuggestion: ThreadServerSuggestion,
    messageGroup: IThreadMessageGroup,
): ThreadSuggestion | undefined {
    const suggestion: ThreadSuggestion = {
        content: serverSuggestion.content,
        action: defaultAction,
    };

    if (!serverSuggestion.action) return suggestion;

    switch (serverSuggestion.action.type) {
        case "get_deeper_insights_on_conversations":
            suggestion.serverAction = {
                type: "GetAdditionalInsightsFromConversations",
                arguments: {
                    topic: serverSuggestion.action.data.topic,
                    conversationIds:
                        serverSuggestion.action.data.conversationIds,
                },
            };
            break;
        case "navigate":
            const domain = serverSuggestion.action.domain;
            if (domain === "conversations") {
                const filters = serverSuggestion.action.data as AvaFilters;

                suggestion.action = (store) =>
                    conversationsNavigationAction(store, filters);

                suggestion.navigationURL = "/app/conversations";
            }
            break;
        case "build_montage":
            const clipData = serverSuggestion.action.data;
            if (clipData.audioSoundClipsCount === undefined) return;

            const agentMessage = messageGroup.messages.at(-1) as ThreadMessage;
            if (clipData.audioSoundClipsCount === 0) {
                agentMessage.appendContent(
                    "\n\nPlease note: none of these clips are audio clips and cannot be included in a montage.",
                );
                return;
            } else if (
                clipData.audioSoundClipsCount < clipData.clipIds.length
            ) {
                agentMessage.appendContent(
                    "\n\nPlease note: some of these clips are not audio clips and will not be included in a montage.",
                );
            }

            suggestion.action = async (store, suggestion) => {
                await store.thread
                    .addUserMessageGroup()
                    .addMessage(suggestion.content)
                    .persist();

                await store.thread
                    .addAgentMessageGroup()
                    .addMessage("I'm taking you to montage builder now.")
                    .persist();

                await store.prepareBuildMontage(clipData);
            };
            suggestion.navigationURL = "/app/montagelibrary";
            break;
        default:
            suggestion.action = () => notImplementedAction(serverSuggestion);
    }

    return suggestion;
}

/**
 * Default behavior is to submit the server suggestions content as the user message
 * @param store
 * @param suggestion
 */
const defaultAction: ThreadSuggestionAction = (store, suggestion) => {
    store.streamingSubmitInput({
        manualInput: suggestion.content,
        suggestion: true,
    });
};

function notImplementedAction(serverSuggestion: ThreadServerSuggestion) {
    console.warn(
        "Server suggestion action not implemented yet",
        serverSuggestion,
    );
}

async function conversationsNavigationAction(
    store: AgentChatStore,
    filters: AvaFilters,
) {
    const rootStore = store.rootStore;
    const conversationsStore = rootStore.getStore(ConversationsStore);
    const applicationFiltersStore = rootStore.getStore(ApplicationFiltersStore);

    if (filters.conversationIds) {
        conversationsStore.setConversationIds(filters.conversationIds);
    } else {
        const properFilters = await avaFiltersToApplicationFilters(filters);
        applicationFiltersStore.applyExternalFilters(properFilters, true);
    }
    conversationsStore.setPageNumberAndGetConversations(1);
}
