import React from "react";
import OrganizationAgentsList from "./OrganizationAgentsList";

type Props = {
    id: string;
};

const OrganizationAgents: React.FC<Props> = ({ id }) => {
    return <OrganizationAgentsList orgId={id} />;
};

export default OrganizationAgents;
