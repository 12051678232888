import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Radio,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Observer } from "mobx-react";
import React from "react";
import { KeysOfType } from "../../utils/TypeUtils";
import AcxSwitch from "./AcxSwitch";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
    },
    focused: {
        "&$focused": {
            color: theme.palette.secondary.main,
        },
    },
    labelText: {
        color: "#1F1F1F",
        fontFamily: "Inter",
        fontSize: "13px",
        letterSpacing: "0",
        lineHeight: "20px",
        userSelect: "none",
    },
    groupLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        userSelect: "none",
        fontSize: "12px",
        lineHeight: "16px",

        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold as any,
    },
}));

type ModelFields<T> = KeysOfType<T, boolean>;
type ModelSetters<T> = KeysOfType<T, (_: boolean) => void>;

export type AcxBooleanFormFieldControllers<T extends {}> = {
    fieldName: ModelFields<T>;
    fieldSetter: ModelSetters<T>;
    displayName: React.ReactNode;
    hide?: boolean;
    infoText?: string;
};

interface OwnProps<T extends {}> {
    formControlModel?: T;
    modelFieldControls: Array<AcxBooleanFormFieldControllers<T>>;

    formLabel?: string;
    formHelperText?: string;
    required?: boolean;
    error?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    indeterminate?: boolean;

    color?: "default" | "primary" | "secondary";
    size?: "medium" | "small";

    control: "checkbox" | "switch" | "radio";
}

function AcxBooleanFormControlGroup<T extends {}>(props: OwnProps<T>) {
    const classes = useStyles();
    const [focused, setFocused] = React.useState<boolean | undefined>();

    return (
        <Observer>
            {() => {
                function handleChange(
                    event: React.ChangeEvent<HTMLInputElement>,
                ) {
                    const fieldControl = props.modelFieldControls.find(
                        (value) => value.fieldName === event.target.name,
                    );

                    if (fieldControl && props.formControlModel) {
                        props.formControlModel?.[
                            fieldControl.fieldSetter as string
                        ](event.target.checked);
                    }
                }

                return (
                    <FormControl
                        hiddenLabel={props.hideLabel}
                        disabled={props.disabled}
                        error={props.error}
                        required={props.required}
                        component="fieldset"
                    >
                        <FormLabel
                            classes={{
                                focused: classes.focused,
                                root: classes.groupLabel,
                            }}
                            focused={focused}
                            disabled={props.disabled}
                            hidden={props.hideLabel}
                            component={"legend"}
                        >
                            {props.formLabel}
                        </FormLabel>

                        <FormGroup
                            onMouseOver={(event) => setFocused(true)}
                            onMouseLeave={(event) => setFocused(false)}
                        >
                            {[
                                ...props.modelFieldControls.filter(
                                    (value) => !value.hide,
                                ),
                            ].map((value) => {
                                return (
                                    <Tooltip
                                        placement={"top"}
                                        enterDelay={250}
                                        enterNextDelay={500}
                                        title={value.infoText ?? ""}
                                        key={value.fieldName as string}
                                    >
                                        <FormControlLabel
                                            key={value.fieldName as string}
                                            control={ChooseControl(
                                                props.control,
                                            )({
                                                checked: props.formControlModel
                                                    ? props.formControlModel?.[
                                                          value.fieldName as string
                                                      ] ?? false
                                                    : false,
                                                indeterminate:
                                                    props.indeterminate,
                                                name: value.fieldName as string,
                                                onChange: handleChange,
                                                disabled: props.disabled,
                                                color: props.color,
                                                size: props.size,
                                            })}
                                            label={
                                                <Typography
                                                    className={
                                                        classes.labelText
                                                    }
                                                >
                                                    {value.displayName}
                                                </Typography>
                                            }
                                        />
                                    </Tooltip>
                                );
                            })}
                        </FormGroup>

                        <FormHelperText>{props.formHelperText}</FormHelperText>
                    </FormControl>
                );
            }}
        </Observer>
    );
}

export default AcxBooleanFormControlGroup;

type ControlProps = {
    checked?: boolean;
    indeterminate?: boolean;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    color?: "default" | "primary" | "secondary";
    disabled?: boolean;
    size?: "medium" | "small";
};

function ChooseControl(control: "checkbox" | "switch" | "radio") {
    switch (control) {
        case "checkbox":
            return (props: ControlProps) => (
                <Observer>{() => <Checkbox {...props} />}</Observer>
            );
        case "radio":
            return (props: ControlProps) => (
                <Observer>{() => <Radio {...props} />}</Observer>
            );
        case "switch":
            return (props: ControlProps) => (
                <Observer>{() => <AcxSwitch {...props} />}</Observer>
            );
    }
}
