import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import { Answer } from "models/Answer";
import { AnswerTypeNames } from "models/AnswerType";
import LicensedModule from "models/LicensedModule";
import Question from "models/Question";
import { Tag } from "models/Tag";
import {
    ConversationService,
    IConversationModuleInfo,
} from "services/ConversationService";
import { ModuleService } from "services/ModuleService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { v4 as uuidv4 } from "uuid";
import { ConvoModuleUIModel } from "../questionComponents/ConvoModuleUIModel";
import { ModulePreview } from "components/Admin/Organizations/types/Module.type";

export const loadConversationDataForDialog =
    "Load Data For Data Labeling Dialog";
export const loadLicensedModule = "Load Selected Licensed Modules";
const createOrUpdateConvoModule = "Create Or Update Conversation Module";

@AcxStore
export default class ConversationLabelingStore extends BaseStore {
    readonly authStore: AuthStore;
    readonly moduleService: ModuleService = new ModuleService();
    readonly conversationService: ConversationService =
        new ConversationService();
    messageStore: MessageStore;

    @observable
    answers: Answer[] = [];

    @observable
    audioMetadataId?: string;

    @observable
    uiModel: ConvoModuleUIModel;

    @observable
    selectedLicensedModuleId?: string;

    @observable
    selectedConversationModuleId?: string;

    @observable
    selectedLicensedModule?: LicensedModule;

    @observable
    conversationLicensedModules: ModulePreview[] = [];

    @observable
    labelConversationDialogOpen: boolean = false;

    @observable
    convoModuleInfo: IConversationModuleInfo[] = [];

    constructor(public rootStore: IRootStore) {
        super("ConvoDataLabelingStore");
        makeObservable(this);
        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);
    }

    @action
    setAnswerForQuestion = (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                undefined,
                question.id,
                question,
                undefined,
                this.authStore._user.profile.email,
                this.authStore._user.profile.email,
            );
            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.conversationModuleId ?? conversationModuleId,
            );
            answer = newAnswer;
        } else {
            this.updateAnswer(
                answer,
                question.id,
                answer.conversationModuleId ?? conversationModuleId,
            );
        }
        answer.setAnswerTags(tags);

        return answer;
    };

    @action
    updateAnswer(
        answer: Answer,
        questionId: string | undefined,
        conversationModuleId: string | undefined,
    ) {
        if (questionId === undefined) {
            return;
        }

        answer.conversationModuleId = conversationModuleId;

        let curAns = this.answers.find(
            (a) => a.questionId === answer.questionId,
        );

        if (curAns) {
            curAns = answer;
        } else {
            this.answers = [...this.answers, answer];
        }
    }

    getAnswerForQuestion = (
        question: Question,
        conversationModuleId: string | undefined,
    ): Answer | undefined => {
        let ans: Answer | undefined = undefined;
        const answers = this.answers.filter(
            (value) =>
                value.questionId === question.id &&
                (!value.conversationModuleId ||
                    value.conversationModuleId === conversationModuleId),
        );

        if (answers.length > 0) {
            ans = answers[0];
        }

        ans?.setQuestion(question);
        return ans;
    };

    @action
    setFillInAnswerForQuestion = (
        question: Question,
        fillInAnswer: string,
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                undefined,
                question.id,
                question,
                undefined,
                this.authStore._user.profile.email,
                this.authStore._user.profile.email,
                fillInAnswer,
            );

            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.conversationModuleId ?? conversationModuleId,
            );
            answer = newAnswer;
        } else {
            answer.fillInAnswerValue = fillInAnswer;
            this.updateAnswer(
                answer,
                question.id,
                answer.conversationModuleId ?? conversationModuleId,
            );
        }

        return answer;
    };

    @action
    toggleLabelConversationDialogOpen = async () => {
        this.labelConversationDialogOpen = !this.labelConversationDialogOpen;
        if (this.labelConversationDialogOpen) {
            this.answers = [];
            this.conversationLicensedModules = [];
            this.selectedLicensedModuleId = undefined;
            this.getConversationLicensedModules();
            this.getConversationModuleInfo();
        }
    };

    public async getConversationModuleInfo() {
        return this.setupAsyncTask(loadConversationDataForDialog, async () => {
            // used for persisted answers after user selected a module
            const convoRes =
                await this.conversationService.getConversationModuleInfo(
                    this.audioMetadataId!,
                );

            runInAction(() => {
                this.convoModuleInfo = convoRes;
            });
        });
    }

    public async getLicensedModuleAndInitUiModel(id: string) {
        return this.setupAsyncTask(loadLicensedModule, async () => {
            const res = await this.moduleService.getLicensedModule(id);
            runInAction(() => {
                this.selectedLicensedModule = LicensedModule.fromJson(res);

                // Add Group Questions (nested questions) to the list of questions
                // in the LicensedModule
                this.selectedLicensedModule.questions = (
                    this.selectedLicensedModule.questions as Array<
                        Question & { questions?: Question[] }
                    >
                )
                    // Workaround for nested questions
                    // We have to place each grouped question into the list of questions
                    // because the backend does not return the grouped questions in the original list of questions
                    .flatMap((question) => {
                        if (
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.QuestionGrouping &&
                            !!question.questions
                        ) {
                            const sortedGroupedQuestions =
                                question.questions.sort(
                                    (questionOne: any, questionTwo: any) =>
                                        questionOne.order - questionTwo.order,
                                );

                            sortedGroupedQuestions.forEach(
                                (groupedQuestion: Question) => {
                                    groupedQuestion.order = question.order;
                                },
                            );

                            return [question].concat(sortedGroupedQuestions);
                        }
                        return question;
                    });

                const existingAnswersForModule = this.convoModuleInfo.find(
                    (cmi) =>
                        cmi.conversationModuleId ===
                        this.selectedConversationModuleId,
                );

                if (existingAnswersForModule) {
                    const formattedAnswers =
                        existingAnswersForModule?.answers?.map((a) => {
                            const formattedAns = Answer.fromJson(a);

                            formattedAns.answerTags =
                                formattedAns.answerTags.map((t) => {
                                    const curQ =
                                        this.selectedLicensedModule?.questions.find(
                                            (q) => {
                                                return (
                                                    q.id ===
                                                    formattedAns.questionId
                                                );
                                            },
                                        );

                                    const tag = curQ?.tags.find(
                                        (tt) => tt.id === t.tagId,
                                    );
                                    t.tag = tag;

                                    return t;
                                });
                            return formattedAns;
                        }) ?? [];

                    this.answers = formattedAnswers;
                } else {
                    this.selectedConversationModuleId = uuidv4();
                }
                this.uiModel = new ConvoModuleUIModel(
                    this.selectedLicensedModule.questions,
                    res,
                    [],
                    this.selectedConversationModuleId,
                );
            });
        });
    }

    public async getConversationLicensedModules() {
        return this.setupAsyncTask(loadConversationDataForDialog, async () => {
            const res =
                await this.moduleService.getConversationLicensedModules();
            runInAction(() => {
                const canViewDataLabelingModules = this.authStore.canUserView("Data Labeling");
                const canViewResearchModules = this.authStore.canUserView("Research");

                const dataLabelingModules = res.filter(module => module.moduleCategory.name === "Data Labeling" && canViewDataLabelingModules);
                const researchModules = res.filter(module => module.moduleCategory.name === "Research" && canViewResearchModules);

                this.conversationLicensedModules = [...dataLabelingModules, ...researchModules];
            });
        });
    }

    public async createConversationModule() {
        return this.setupAsyncTask(createOrUpdateConvoModule, async () => {
            if (
                !this.selectedConversationModuleId ||
                !this.selectedLicensedModule?.id ||
                !this.audioMetadataId
            )
                return;
            try {
                await this.conversationService.createConversationModule({
                    conversationModule: {
                        id: this.selectedConversationModuleId,
                        licensedModuleId: this.selectedLicensedModule?.id,
                        audioMetadataId: this.audioMetadataId,
                    },
                    answers: this.answers,
                });
                this.messageStore.logMessage(
                    "Conversation labeled.",
                    "success",
                );
            } catch (e) {
                this.messageStore.logError("Failed to label conversation.");
                console.error(JSON.stringify(e));
            }
        });
    }

    public async updateConversationModule() {
        return this.setupAsyncTask(createOrUpdateConvoModule, async () => {
            if (
                !this.selectedConversationModuleId ||
                !this.selectedLicensedModule?.id ||
                !this.audioMetadataId
            )
                return;

            try {
                await this.conversationService.updateConversationModule({
                    conversationModuleId: this.selectedConversationModuleId,
                    answers: this.answers,
                });
                this.messageStore.logMessage(
                    "Conversation labeled.",
                    "success",
                );
            } catch (e) {
                this.messageStore.logError("Failed to label conversation.");
                console.error(JSON.stringify(e));
            }
        });
    }

    isMissingRequiredAnswer(renderedQuestions: Question[]): boolean {
        let isMissing = false;
        if (renderedQuestions.length) {
            const requiredQuestions = renderedQuestions.filter(
                (q) => q.required,
            );

            const requiredAnswers = this.answers.filter((a) =>
                requiredQuestions.map((q) => q.id).includes(a.questionId),
            );

            requiredQuestions.forEach((q) => {
                const foundAns = requiredAnswers.find(
                    (a) => a.questionId === q.id,
                );

                if (!foundAns) {
                    isMissing = true;
                    // In this else if block, we want to check if the answer is a fill in answer
                    // If it is, we don't want to accidentally trigger the next else if so
                    // we need to check if the fillInAnswerValue is empty separately
                } else if (q.answerType.isFillInAnswer) {
                    if (!foundAns.fillInAnswerValue) {
                        isMissing = true;
                    }
                    // The isAnswered check DOES NOT work for a fill in answer when initially loading
                    // because the question attribute on the answer is not always set
                } else if (!foundAns.isAnswered) {
                    isMissing = true;
                }
            });
        }

        return isMissing;
    }
}
