import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import {
    Configuration
} from "models/OrganizationModels/OrganizationMetadataConfiguration";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { v4 as uuidv4 } from "uuid";

export type SourceFieldType = {
    isRequired: boolean;
    path: string;
    alias: string;
    extractionPattern: string;
    id: string;
};

const initialSourceFields: SourceFieldType[] = [
    {
        isRequired: true,
        path: "",
        alias: "",
        extractionPattern: "",
        id: uuidv4(),
    },
];

@AcxStore
export class OrganizationDataSourcesStore extends BaseStore {
    readonly datasourcesDgStore: AcxDataGridStore;
    private readonly orgService: OrganizationService =
        new OrganizationService();

    @observable activeStep: number = 0;
    @observable shouldApplyConditions: boolean = false;
    @observable shouldConcatenateFields: boolean = false;
    @observable sourceFields: SourceFieldType[] = initialSourceFields;
    @observable selectedDatasource?: Configuration;

    constructor(private rootStore: IRootStore) {
        super("OrganizationDatasourcesStore");
        makeObservable(this);

        console.log("You are in OrganizationDatasourcesStore constructor");
        this.datasourcesDgStore = new AcxDataGridStore(
            "OrganizationDatasourcesStore",
            "OrganizationDatasource",
        );

        this.datasourcesDgStore.checkboxSelection = false;
        this.datasourcesDgStore.hideFilter = true;
        this.datasourcesDgStore.hideVertIcon = true;
        this.datasourcesDgStore.rows = [];
    }

    setSourceFieldsFromData = (datasource: Configuration) => {
        const sourceFields: SourceFieldType[] = datasource.fields.map(
            (field) => ({
                isRequired: field.isRequired,
                alias: "",
                id: field.id,
                extractionPattern: field.extractionPattern || "",
                path: field.xPathString,
            }),
        );
        this.setSourceFields(sourceFields);
    };

    @action
    setActiveStep = (step: number) => {
        this.activeStep = step;
    };

    @action
    setSourceFields = (fields: SourceFieldType[]) => {
        this.sourceFields = fields;
    };

    @action
    setShouldApplyConditions = (applyConditions: boolean) => {
        this.shouldApplyConditions = applyConditions;
    };

    @action
    setShouldConcatenateFields = (concatenateFields: boolean) => {
        this.shouldConcatenateFields = concatenateFields;
    };

    @action
    setSelectedDatasource = (datasource: Configuration) => {
        this.selectedDatasource = datasource;
        this.setSourceFieldsFromData(datasource);
    };

    @action
    loadDataSources = async () => {
        this.datasourcesDgStore.isLoading = true;
        this.setupAsyncTask("Load Data Sources", async () => {
            const config =
                await this.orgService.getOrganizationMetadataConfiguration();
            runInAction(() => {
                this.datasourcesDgStore.rows = config;
                this.datasourcesDgStore.isLoading = false;
            });
        });
    };
}
