import RootStore, { ActiveDomain } from "stores/RootStore";
import { IAgentContext } from "./IContext";
import Conversation from "models/Conversation";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import Evaluation from "models/Evaluation";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";

abstract class DomainValidator {
    constructor(private domain: ActiveDomain) {}

    protected validateDomain() {
        const activeLocation = RootStore().activeLocation;
        if (activeLocation.domain !== this.domain)
            throw new Error("Invalid use of location based context.");
    }
}

class SingleConversationContext
    extends DomainValidator
    implements IAgentContext<ActiveDomain.Conversation, Conversation>
{
    type: ActiveDomain.Conversation;

    constructor() {
        super(ActiveDomain.Conversation);
        this.type = ActiveDomain.Conversation;
    }

    getValue(): Conversation {
        this.validateDomain();

        const store = RootStore().getStore(ConversationsStore);
        if (!store.selectedConversation) throw new Error("");

        return store.selectedConversation;
    }
}

class EvaluationContext
    extends DomainValidator
    implements IAgentContext<ActiveDomain.Evaluation, Evaluation>
{
    type: ActiveDomain.Evaluation;

    constructor() {
        super(ActiveDomain.Evaluation);
        this.type = ActiveDomain.Evaluation;
    }

    getValue(): Evaluation {
        this.validateDomain();

        const store = RootStore().getStore(EvalStore);
        if (!store.currentEval) throw new Error("");

        return store.currentEval;
    }
}

export namespace DomainBasedContext {
    export const SingleConversation = new SingleConversationContext();
    export const Evaluation = new EvaluationContext();
}
