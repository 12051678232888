import { Container, FormControlLabel, Grid, Theme } from "@mui/material";
import { Create } from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { Observer } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { OrganizationMotfStore } from "./OrganizationMotfStore";
import AcxSwitch from "components/UI/AcxSwitch";

const styles = (theme: Theme) => {
    return {
        root: {
            padding: theme.spacing(2),
        },
        flex: {
            gap: theme.spacing(2),
        },
    };
};

export default observer(function OrganizationMotfDefinitions({
    id,
}: {
    id: string;
}) {
    const classes = useStyles(styles);

    const navigate = useNavigate();

    const motfStore = useStore(OrganizationMotfStore);
    React.useEffect(() => {
        motfStore.setOrgId(id);
    }, [motfStore, id]);

    React.useEffect(() => {
        motfStore.loadDefinitions();
        async function onEditClick() {
            const id = motfStore.definitionsDataGridStore.selectedRowIds[0];
            await motfStore.setEditingDefinitionById(id as string);
            navigate(`../edit`);
        }

        function onNewDefinitionClick() {
            navigate(`../create`);
        }
        motfStore.definitionsDataGridStore.controls = [
            <Observer>
                {() => (
                    <Grid item>
                        <AcxButton
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => {
                                motfStore.setIsHistoryOpen(true);
                            }}
                        >
                            Execution History
                        </AcxButton>
                    </Grid>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <Grid item>
                        <AcxButton
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={onNewDefinitionClick}
                            disabled={!motfStore.canUserEdit}
                            startIcon={<Create />}
                        >
                            New Definition
                        </AcxButton>
                    </Grid>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <Grid item>
                        <AcxButton
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={onEditClick}
                            disabled={!motfStore.canEditSelected}
                        >
                            Edit
                        </AcxButton>
                    </Grid>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <Grid item>
                        <AcxButton
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => motfStore.deactivateSelected()}
                            disabled={!motfStore.canDeactivateSelected}
                        >
                            Deactivate Selected
                        </AcxButton>
                    </Grid>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <Grid item style={{ marginLeft: "1rem" }}>
                        <FormControlLabel
                            control={
                                <AcxSwitch
                                    checked={motfStore.showInactiveDefinitions}
                                    onChange={() =>
                                        motfStore.setShowInactiveDefinitions(
                                            !motfStore.showInactiveDefinitions,
                                        )
                                    }
                                />
                            }
                            label="Display Inactive"
                        />
                    </Grid>
                )}
            </Observer>,
        ];
    }, [motfStore, navigate]);

    return (
        <Container className={classes.root} maxWidth="xl">
            <Grid style={{ height: "100%" }} direction="column">
                <AcxDataGrid
                    dataGridStore={motfStore.definitionsDataGridStore}
                />
            </Grid>
            <AcxDialog
                title={" "}
                text=""
                dialogContentChildren={
                    <Grid style={{ height: "75vh" }}>
                        <AcxDataGrid
                            dataGridStore={motfStore.historyDataGridStore}
                        />
                    </Grid>
                }
                isOpen={motfStore.isHistoryOpen}
                onClose={() => {
                    motfStore.setIsHistoryOpen(false);
                }}
                contentWidth="90vw"
                maxWidth="xl"
                disableEnforceFocus
            ></AcxDialog>
        </Container>
    );
});
