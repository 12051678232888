import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Theme,
    Typography,
    Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import AcxButton from "../AcxButton";
import theme from "Theme/AppTheme";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    buttonsDisabled?: boolean;
    title: React.ReactNode;
    subTitle?: string;
    text?: string;
    dialogContentChildren?: any;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    contentWidth?: string;
    children?: React.ReactNode;
    disableEnforceFocus?: boolean;
    textAlignCenterContent?: boolean;
    textAlignCenterTitle?: boolean;
    hideCloseButton?: boolean;
    hideCancelButton?: boolean;
    centerActionButtons?: boolean;
    fullScreen?: boolean;
};

const useAcxDialogStyles = makeStyles((theme: Theme) => ({
    closeButton: {
        position: "absolute",
        right: "0.25rem",
        top: 0,
        color: theme.palette.black.main,
        opacity: 1,
    },
    content: {
        fontSize: "13px",
        color: theme.palette.black.main,
        height: "100%",
    },
    actions: (props: Props) => {
        const actionStyles = { height: "64px" };
        if (props.centerActionButtons) {
            const newStyles = { ...actionStyles, justifyContent: "center" };
            return newStyles;
        }

        return actionStyles;
    },
}));

const AcxDialog: React.FC<Props> = (props) => {
    const {
        children,
        isOpen,
        onClose,
        buttonsDisabled,
        title,
        subTitle,
        text,
        dialogContentChildren,
        maxWidth,
        contentWidth,
        disableEnforceFocus,
        textAlignCenterContent,
        textAlignCenterTitle,
        hideCloseButton,
        hideCancelButton,
        fullScreen,
    } = props;

    const classes = useAcxDialogStyles(props);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={maxWidth}
            disableEnforceFocus={disableEnforceFocus}
            fullScreen={fullScreen}
            hideBackdrop={fullScreen}
            style={
                fullScreen
                    ? {
                          height: `calc(100% - 72px)`,
                          width: "100dvw",
                          top: "72px",
                          left: "0px",
                          right: "0px",
                          bottom: "0px",
                          position: "relative",
                          zIndex: 1299,
                      }
                    : {}
            }
            PaperProps={
                fullScreen
                    ? {
                          style: {
                              boxShadow: "none",
                              backgroundColor: theme.palette.lightgray.main,
                          },
                      }
                    : {}
            }
        >
            <DialogTitle id="alert-dialog-title" sx={{ opacity: 1 }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Typography
                        component="div"
                        variant="h1"
                        align={textAlignCenterTitle ? "center" : "left"}
                    >
                        {title || "Please Confirm..."}
                    </Typography>

                    {!hideCloseButton && (
                        <IconButton
                            aria-label="close"
                            disabled={buttonsDisabled}
                            className={classes.closeButton}
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
                {Boolean(subTitle) && (
                    <Typography
                        component="span"
                        variant="subtitle2"
                        style={{ marginTop: "1rem" }}
                        color={"textSecondary"}
                    >
                        {subTitle}
                    </Typography>
                )}
            </DialogTitle>

            <DialogContent
                className={classes.content}
                style={contentWidth ? { width: contentWidth } : {}}
            >
                <DialogContentText style={text ? {} : { margin: 0 }}>
                    <Typography component="span" align="left">
                        <Grid
                            container
                            item
                            style={
                                textAlignCenterContent
                                    ? { textAlign: "center" }
                                    : {}
                            }
                            sx={{ paddingBottom: "10px" }}
                        >
                            {text}
                        </Grid>
                    </Typography>
                </DialogContentText>

                {dialogContentChildren}
            </DialogContent>

            <DialogActions
                className={classes.actions}
                style={
                    fullScreen
                        ? { backgroundColor: theme.palette.white.main }
                        : {}
                }
            >
                {!hideCancelButton && (
                    <AcxButton
                        disabled={buttonsDisabled ?? false}
                        onClick={onClose}
                        color="white"
                    >
                        Cancel
                    </AcxButton>
                )}

                {children}
            </DialogActions>
        </Dialog>
    );
};

export default AcxDialog;
