import CachedIcon from "@mui/icons-material/Cached";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Divider, FormControlLabel, Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import WorkflowStore, {
    AgingView,
    CANCEL_WORKFLOWS,
    LOAD_AGING_DATA,
    REOPEN_WORKFLOW,
} from "components/Workflows/Stores/WorkflowStore";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import AWOverviewChart from "./AWOverviewChart";
import { GridValidRowModel } from "@mui/x-data-grid-pro";
import { useStore } from "utils/useStore";
import AWOverviewChartStore from "components/Workflows/Stores/AWOverviewChartStore";
import AcxSwitch from "components/UI/AcxSwitch";

type Props = {
    store: WorkflowStore;
};

const workflowsDateReferenceOptions = [
    DateReferenceOption.CreatedOnDate,
    DateReferenceOption.InteractionDate,
];

const AgingWorkflows: React.FC<Props> = observer(({ store }) => {
    const chartStore = useStore(AWOverviewChartStore);
    const [views, setViews] = useState<{ value: number; element: string }[]>(
        EnumToDropdownOptionConversion(AgingView).map((view) => {
            return { value: view.value, element: view.label };
        }),
    );
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const getControls = (): CustomControlItem[] => {
        const refreshTable = (
            <Observer>
                {() => (
                    <Grid item container justifyContent="flex-end">
                        <AcxButton
                            disabled={store.anyTaskLoading}
                            loading={store.getTaskLoading(LOAD_AGING_DATA)}
                            style={{
                                height: "32px",
                                marginRight: "auto",
                                marginLeft: "auto",
                            }}
                            tooltip="Refresh Table"
                            color="primary"
                            onClick={store.refreshTable}
                        >
                            <CachedIcon />
                        </AcxButton>
                    </Grid>
                )}
            </Observer>
        );
        const dateRangePicker = (
            <Observer>
                {() => (
                    <Grid item container justifyContent="flex-end">
                        <AcxDateRangeInput
                            defaultStartDate={store.datePickerStore.beginDate}
                            defaultEndDate={store.datePickerStore.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                store.datePickerStore.setBeginDate(start);
                                store.datePickerStore.setEndDate(end);
                            }}
                            displayDateType
                            dateReferenceOptions={workflowsDateReferenceOptions}
                            datePickerStore={store.datePickerStore}
                        />
                    </Grid>
                )}
            </Observer>
        );
        const hideClosedWorkflowsSwitch = (
            <FormControlLabel
                control={
                    <AcxSwitch
                        onChange={store.toggleHideClosedWorkflows}
                        checked={store.hideClosedWorkflows}
                    />
                }
                label="Hide Closed Workflows"
            />
        );
        const cancelWorkflow = (
            <Observer>
                {() => (
                    <Grid item container justifyContent="flex-end">
                        <AcxButton
                            fullWidth
                            disabled={
                                !store.rowIsSelected || store.anyTaskLoading
                            }
                            loading={store.getTaskLoading(CANCEL_WORKFLOWS)}
                            style={{
                                height: "32px",
                                marginRight: "auto",
                                marginLeft: "auto",
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            tooltip="Cancel Workflow"
                            startIcon={<CancelIcon />}
                            color="primary"
                            onClick={store.cancelWorkflows}
                        >
                            Cancel
                        </AcxButton>
                    </Grid>
                )}
            </Observer>
        );

        const reopenWorkflow = (
            <Observer>
                {() => (
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        style={{ paddingRight: "0.5rem" }}
                    >
                        <AcxButton
                            fullWidth
                            disabled={
                                !store.rowIsSelected ||
                                !store.selectedStatusIsAPT ||
                                store.multipleRowsSelected ||
                                !store.selectedStatusIsClosed ||
                                store.anyTaskLoading
                            }
                            loading={store.getTaskLoading(REOPEN_WORKFLOW)}
                            style={{
                                height: "32px",
                                marginRight: "auto",
                                marginLeft: "auto",
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            tooltip="Reopen Workflow (APT only)"
                            startIcon={<SettingsBackupRestoreIcon />}
                            color="primary"
                            onClick={() => {
                                setShowReopenDialog((prev) => !prev);
                                store.getValidWorkflowReopenLevelOptions();
                                store.setReopenLevel();
                            }}
                        >
                            Reopen
                        </AcxButton>
                    </Grid>
                )}
            </Observer>
        );
        let controlsArray = [
            {
                controlElement: hideClosedWorkflowsSwitch,
                style: { marginRight: "auto" },
            },
            { controlElement: dateRangePicker },
            { controlElement: refreshTable },
        ];
        if (store.authStore.permStore.isElevated) {
            controlsArray.push({ controlElement: cancelWorkflow });
            controlsArray.push({ controlElement: reopenWorkflow });
        }
        return controlsArray;
    };

    store.dgStore.controls = getControls();

    const onRowCountChange = useCallback(() => {
        if (store.dgStore.gridApi?.current) {
            let pieSeriesNames: string[] = [];

            const prioritySeriesNames = ["Priority", "Normal"];
            const statusSeriesNames = ["In Progress", "Closed", "Overdue"];

            const pieDataCounts: [string, number][] = [];

            if (chartStore.selectedChartType === "Priority") {
                pieSeriesNames = prioritySeriesNames;
            } else if (chartStore.selectedChartType === "Status") {
                pieSeriesNames = statusSeriesNames;
            }

            pieSeriesNames.forEach((seriesName: string) => {
                const countCurrentRow = (row: GridValidRowModel) => {
                    const rowIsVisible = !!store.dgStore.gridApi?.current?.state
                        ?.filter.filterModel?.items.length
                        ? store.dgStore.gridApi?.current?.state
                              ?.visibleRowsLookup[row.id]
                        : true;

                    if (chartStore.selectedChartType === "Status") {
                        let statusRealName = seriesName;
                        if (seriesName === "In Progress") {
                            statusRealName = "Open";
                        }

                        return (
                            row.currentStatus === statusRealName && rowIsVisible
                        );
                    } else if (chartStore.selectedChartType === "Priority") {
                        return seriesName === "Priority"
                            ? row.highPriority && rowIsVisible
                            : !row.highPriority && rowIsVisible;
                    } else {
                        return false;
                    }
                };

                const seriesCount =
                    store.dgStore.rows.filter(countCurrentRow).length;

                pieDataCounts.push([seriesName, seriesCount]);
            });

            chartStore.setChartData([["Status", "Counts"], ...pieDataCounts]);
        }
    }, [chartStore, store.dgStore.gridApi, store.dgStore.rows]);

    useEffect(() => {
        store.dgStore.onRowCountChange = onRowCountChange;
    }, [onRowCountChange, store.dgStore, store.dgStore.gridApi]);

    useEffect(() => {
        onRowCountChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartStore.selectedChartType]);

    useEffect(() => {
        store.refreshTable();
    }, [store]);

    useEffect(() => {
        store.dgStore.controlMargin = {
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            marginBottom: "0.5rem",
            marginTop: "1.5rem",
        };
        store.dgStore.removeHeight = "7.5rem";
        store.dgStore.controlsColumnSpan = 12;
    }, [
        store,
        store.datePickerStore,
        store.dgStore,
        store.refreshTable,
        store.hideClosedWorkflows,
        store.datePickerStore.referenceOption,
    ]);

    const userCanEdit = store.authStore.canUserEdit("Workflows");

    useEffect(() => {
        if (!userCanEdit) {
            setViews(
                views.filter(
                    (view) => view.value !== AgingView["My Organization"],
                ),
            );

            store.handleAgingViewChange(AgingView["Me"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.authStore.permStore, userCanEdit]);

    useEffect(() => {
        if (!!store.dgStore.columns?.length) {
            store.dgStore.gridApi?.current?.setSortModel?.([
                { field: "highPriority", sort: "desc" },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.dgStore.columns]);

    return (
        <Grid container item direction="column">
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item>
                    <h1>Workflows</h1>
                </Grid>
                <Grid
                    container
                    item
                    alignItems="center"
                    style={{ width: "fit-content" }}
                    spacing={1}
                >
                    {userCanEdit && (
                        <>
                            <Grid item>
                                <AcxToggleButton
                                    id="interaction-filter-option"
                                    exclusive
                                    onChange={store.handleAgingViewChange}
                                    spacing={3}
                                    items={views}
                                    defaultValue={store.selectedAgingView}
                                    darkBg
                                />
                            </Grid>

                            <Grid item>
                                <AcxHierarchySelector
                                    orgId={store.orgId}
                                    displayType="input"
                                    userId={store.authStore._user.profile.sub}
                                    setHierarchyIds={store.setHierarchyIds}
                                    onSaveUpdateWithHierarchies={
                                        store.onSaveUpdateWithHierarchies
                                    }
                                    placeholder="Select Hierarchy"
                                    popperPlacement="bottom"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid container item style={{ minHeight: "75dvh" }} spacing={4}>
                <Grid item xs={9} sx={{ height: "calc(100vh - 230px)" }}>
                    <AcxDataGrid dataGridStore={store.dgStore} />
                </Grid>
                <Grid
                    container
                    item
                    xs={3}
                    direction="column"
                    style={{ marginTop: "52px" }}
                >
                    <AWOverviewChart
                        store={store}
                        gridApiRef={store.dgStore.gridApi}
                        chartStore={chartStore}
                    />
                </Grid>
            </Grid>
            <AcxDialog
                isOpen={showReopenDialog}
                title="Reopen Workflow"
                text="Reopen a closed workflow instance. Select level to reopen to."
                onClose={() => setShowReopenDialog(false)}
                dialogContentChildren={
                    <Grid
                        container
                        style={{ paddingTop: "32px", paddingBottom: "48px" }}
                    >
                        <AcxSelect
                            defaultValue={
                                store.selectedReopenLevel
                                    ? {
                                          label: store.selectedReopenLevel,
                                          value: store.selectedReopenLevel,
                                      }
                                    : null
                            }
                            options={store.validWorkflowReopenLevelOptions?.map(
                                (item) => ({ label: item, value: item }),
                            )}
                            id={"status-filter-selector"}
                            isMulti={false}
                            inputLabel="Select Level to Reopen Workflow Instance"
                            onChange={(item) =>
                                store.setReopenLevel(item.value)
                            }
                            required
                            isLoading={store.anyTaskLoading}
                            placeholder="Select Level"
                            fullWidth
                        />
                    </Grid>
                }
                contentWidth="450px"
            >
                <AcxButton
                    onClick={() => {
                        setShowReopenDialog(false);
                        setShowConfirm(true);
                    }}
                    disabled={!store.selectedReopenLevel}
                    color="primary"
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    Reopen Workflow
                </AcxButton>
            </AcxDialog>
            <AcxDialog
                isOpen={showConfirm}
                title="Confirm Reopen"
                text={`Are you sure you want to reopen Workflow Instance ${store.dgStore.selectedRowIds[0]}? Reopening this workflow instance will notify workflow recipent and reviewer(s).`}
                onClose={() => setShowConfirm(false)}
                contentWidth="450px"
                textAlignCenterContent
                textAlignCenterTitle
                hideCloseButton
                centerActionButtons
            >
                <AcxButton
                    fullWidth
                    onClick={() => {
                        store.reopenWorkflow();
                        setShowConfirm(false);
                    }}
                    color="primary"
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    Reopen Workflow
                </AcxButton>
            </AcxDialog>
        </Grid>
    );
});

export default AgingWorkflows;
