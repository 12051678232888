import CallSplitIcon from "@mui/icons-material/CallSplit";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import StarRateIcon from "@mui/icons-material/StarRate";
import SubjectIcon from "@mui/icons-material/Subject";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Button, FormControlLabel, Grid, List, ListItem } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { Module, SkinnyModule } from "../../types/Module.type";
import ServiceHierarchySelect from "./fields/ServiceHierarchySelect";
import useOrganizationModule from "./hooks/useOrganizationModules";
import ModuleService from "./Module.service";
import AcxSwitch from "components/UI/AcxSwitch";

const Accordion = withStyles({
    root: {
        width: "100%",
        borderRadius: 4,
        "&:before": {
            display: "none",
        },
        margin: "4px 0",
        "&$expanded": {
            margin: "16px 0",
        },
        boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 4px 1px -1px, rgba(0, 0, 0, 0.02) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 2px 6px 0px",
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 72,
        "&$expanded": {
            minHeight: 72,
        },
    },
    content: {
        display: "flex",
        alignItems: "center",
        margin: "12px 0",
        justifyContent: "space-between",
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const styles = (theme: Theme) => {
    return createStyles({
        pageContainer: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: theme.typography.pxToRem(20),
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            flexBasis: "20%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            maxHeight: "200px",
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.secondary,
            width: "100%",
        },
        descriptionContainer: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.secondary,
            width: "100%",
            marginRight: "1rem",
            marginLeft: "1rem",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 6,
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        editButton: {
            textTransform: "none",
            fontWeight: "bold",
        },
        list: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            borderTop: "1px solid #e5e5e5",
        },
        shape: {
            backgroundColor: theme.palette.secondary.main,
            width: theme.typography.pxToRem(24),
            height: theme.typography.pxToRem(24),
            minWidth: theme.typography.pxToRem(24),
            minHeight: theme.typography.pxToRem(24),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            fontSize: theme.typography.pxToRem(14),
            fontStyle: "bold",
            marginRight: theme.typography.pxToRem(12),
        },
        shapeCircle: {
            borderRadius: "50%",
        },
        lineLeft: {
            display: "flex",
            alignItems: "center",
            flexBasis: "60%",
            flexShrink: 0,
        },
        lineMiddle: {
            minWidth: "90px",
        },
        lineRight: {
            textAlign: "right",
            flexBasis: "20%",
            flexShrink: 0,
        },
        lineMiddleContent: {
            display: "flex",
            flexBasis: "20%",
            flexShrink: 0,
            alignSelf: "start",
        },
        lineRightContent: {
            textAlign: "right",
            flexBasis: "20%",
            flexShrink: 0,
            alignSelf: "start",
            paddingRight: theme.typography.pxToRem(8),
        },
        listPrimaryText: {
            fontWeight: 800,
            fontSize: theme.typography.pxToRem(13),
            display: "block",
        },
        listSecondaryText: {
            color: "#1f1f1f",
            display: "flex",
            alignItems: "center",
            fontSize: theme.typography.pxToRem(13),
            paddingTop: theme.typography.pxToRem(6),
        },
        listItem: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        responseTypeIcon: {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            marginRight: theme.typography.pxToRem(4),
        },
        filterIcon: {
            marginLeft: theme.typography.pxToRem(6),
            cursor: "pointer",
        },
        formControl: {
            marginLeft: 0,
            marginRight: 0,
            width: "100%",
        },
        questionTypeIcon: {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            marginRight: theme.typography.pxToRem(4),
        },
        accordionSummary: {
            maxHeight: "200px",
            overflow: "hidden",
        },
        truncateName: {
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
    });
};

interface Props {
    orgId: string;
}

const OrganizationModulesList: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ orgId }) => {
    const authStore = useStore(AuthStore);
    const classes = useStyles(styles);
    const [openModules, setOpenModules] = useState({});
    const [moduleDetails, setModuleDetails] = useState<Record<string, Module>>(
        {},
    );
    const { modules, isLoading, refetch, duplicate } =
        useOrganizationModule(orgId);
    const [filteredModules, setFilteredModules] = useState<SkinnyModule[]>([]);
    const [selectedHierarchyOptions, setSelectedHierarchyOptions] = useState<
        string[]
    >([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showInactiveModules, setShowInactiveModules] = useState(false);

    useEffect(() => {
        if (selectedHierarchyOptions?.length) {
            const modulesToShow = modules?.filter((module) =>
                module.organizationStructureMemberIds.some((memberId) =>
                    selectedHierarchyOptions.includes(memberId),
                ),
            );
            setFilteredModules(modulesToShow || []);
        } else {
            if (showInactiveModules) {
                setFilteredModules(modules || []);
            } else {
                const modulesToShow = modules?.filter(
                    (module) => module.isActive,
                );
                setFilteredModules(modulesToShow || []);
            }
        }
    }, [selectedHierarchyOptions, modules, isLoading, showInactiveModules]);

    const questionTypes = {
        "Scaled Response": {
            icon: <SubjectIcon className={classes.responseTypeIcon} />,
        },
        "Tag Response": {
            icon: <LocalOfferIcon className={classes.responseTypeIcon} />,
        },
        "Star Rating": {
            icon: <StarRateIcon className={classes.responseTypeIcon} />,
        },
        "Scaled Boolean Response": {
            icon: <CallSplitIcon className={classes.responseTypeIcon} />,
        },
        "Scored Tag Response": {
            icon: <TrendingUpIcon className={classes.responseTypeIcon} />,
        },
        "Question Group": {
            icon: <GroupWorkIcon className={classes.responseTypeIcon} />,
        },
    };

    const handleChange = (panel, moduleId) => (event, newExpanded) => {
        fetchModuleDetails(moduleId);
        setOpenModules((prev) => ({
            ...prev,
            [panel]: !prev[panel],
        }));
    };

    const fetchModuleDetails = async (moduleId: string) => {
        if (!moduleDetails[moduleId]) {
            const module = await ModuleService.getModule(moduleId);
            setModuleDetails((prev) => ({
                ...prev,
                [moduleId]: module,
            }));
            return module;
        }

        return moduleDetails[moduleId];
    };

    const handleModuleToggle = async (module: SkinnyModule) => {
        // Try to get the stored module details, if not found, fetch them
        let moduleToToggle = moduleDetails[module.id];
        if (!moduleToToggle) {
            moduleToToggle = await fetchModuleDetails(module.id);
        }

        // Toggle using the full module details
        await ModuleService.toggleModule(moduleToToggle);
        refetch();
    };

    const toggleFilter = () => {
        setShowFilter((prev) => !prev);
    };

    return (
        <>
            <div className={classes.pageContainer}>
                <Grid
                    style={{
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                    }}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={7}>
                        <Typography variant="subtitle1">
                            Modules{" "}
                            <FilterListIcon
                                className={[
                                    classes.responseTypeIcon,
                                    classes.filterIcon,
                                ].join(" ")}
                                onClick={toggleFilter}
                                color={showFilter ? "secondary" : "inherit"}
                            />
                        </Typography>
                    </Grid>

                    {authStore.canUserEdit("Modules") && (
                        <Grid
                            container
                            item
                            xs={5}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid
                                container
                                item
                                xs={7}
                                justifyContent="flex-end"
                            >
                                <FormControlLabel
                                    style={{ marginRight: "0.75rem" }}
                                    control={
                                        <AcxSwitch
                                            onChange={() => {
                                                setOpenModules({});
                                                setShowInactiveModules(
                                                    (prev) => !prev,
                                                );
                                            }}
                                        />
                                    }
                                    label="Display Inactive"
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={5}
                                justifyContent="flex-end"
                            >
                                <Link
                                    to={`/focus/modules/${orgId}/add`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <AcxButton
                                        color="primary"
                                        style={{
                                            margin: "0px",
                                            maxWidth: "max-content",
                                        }}
                                    >
                                        Add Module
                                    </AcxButton>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {showFilter && (
                    <Grid
                        style={{
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                        }}
                        container
                    >
                        <ServiceHierarchySelect
                            orgId={orgId}
                            value={selectedHierarchyOptions}
                            onChange={(val: string[]) =>
                                setSelectedHierarchyOptions(val)
                            }
                        />
                    </Grid>
                )}
                {filteredModules?.map((module, i) => (
                    <Accordion
                        key={i}
                        square
                        expanded={openModules[i] || false}
                        onChange={handleChange(i, module.id)}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={
                                module.isActive
                                    ? {}
                                    : {
                                          backgroundColor: "#c7c7c7",
                                          borderRadius: 4,
                                      }
                            }
                            className={classes.accordionSummary}
                        >
                            {showInactiveModules && (
                                <FormControlLabel
                                    control={
                                        <AcxSwitch
                                            onChange={() =>
                                                handleModuleToggle(module)
                                            }
                                            checked={module.isActive}
                                            disabled={isLoading}
                                        />
                                    }
                                    aria-label="toggle-active"
                                    label=""
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                />
                            )}
                            <Typography
                                component="span"
                                className={classes.heading}
                                style={
                                    module.isActive
                                        ? { color: "black" }
                                        : { color: "gray" }
                                }
                            >
                                {openModules[i] ? (
                                    <KeyboardArrowDownIcon />
                                ) : (
                                    <KeyboardArrowRightIcon />
                                )}{" "}
                                <div className={classes.truncateName}>
                                    {module.name}
                                </div>
                            </Typography>

                            <Typography
                                className={classes.descriptionContainer}
                            >
                                {module.description}
                            </Typography>
                            {authStore.canUserEdit("Modules") && (
                                <div>
                                    <Link
                                        to={`/focus/modules/${orgId}/${module.id}`}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            color="primary"
                                            startIcon={<EditOutlinedIcon />}
                                            className={classes.editButton}
                                        >
                                            Edit
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            {!!moduleDetails[module.id] ? (
                                <List className={classes.list}>
                                    {moduleDetails[module.id].questions.map(
                                        (question, i) => (
                                            <ListItem
                                                className={classes.listItem}
                                                key={i}
                                            >
                                                <div
                                                    className={classes.lineLeft}
                                                >
                                                    <div
                                                        className={clsx(
                                                            classes.shape,
                                                            classes.shapeCircle,
                                                        )}
                                                    >
                                                        {i + 1}
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={
                                                                classes.listPrimaryText
                                                            }
                                                        >
                                                            {
                                                                question.questionText
                                                            }
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.listSecondaryText
                                                            }
                                                        >
                                                            {
                                                                questionTypes[
                                                                    question
                                                                        .answerType
                                                                        .answerTypeName
                                                                ]?.icon
                                                            }{" "}
                                                            {question.tags
                                                                ?.map(
                                                                    (tag) =>
                                                                        tag.value,
                                                                )
                                                                ?.join(", ")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        classes.lineMiddleContent
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.secondaryHeading
                                                        }
                                                    >
                                                        Score: {question.score}
                                                    </Typography>
                                                </div>
                                                <div
                                                    className={
                                                        classes.lineRightContent
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.secondaryHeading
                                                        }
                                                    >
                                                        {
                                                            question.answerType
                                                                .answerTypeName
                                                        }
                                                    </Typography>
                                                </div>
                                            </ListItem>
                                        ),
                                    )}
                                    <Grid
                                        style={{
                                            marginTop: theme.spacing(2),
                                            marginBottom: theme.spacing(1),
                                        }}
                                        container
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                startIcon={
                                                    <ControlPointDuplicateIcon />
                                                }
                                                className={classes.editButton}
                                                onClick={() => {
                                                    duplicate(
                                                        moduleDetails[
                                                            module.id
                                                        ],
                                                    );
                                                    setOpenModules({});
                                                }}
                                                disabled={
                                                    !authStore.canUserEdit(
                                                        "Modules",
                                                    )
                                                }
                                            >
                                                Duplicate Module
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </List>
                            ) : (
                                <AcxLoadingIndicator
                                    alternate="PuffLoader"
                                    size={64}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    );
});

export default OrganizationModulesList;
