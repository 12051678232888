import React from "react";
import useStyles from "Styles/Styles";
import OrganizationWorkflowsWrapper from "./OrganizationWorkflowsWrapper";

type Props = {
    id: string;
};

const OrganizationWorkflows: React.FC<Props> = ({ id }) => {
    const classes = useStyles(() => {
        return {};
    });

    return (
        <div className={classes.appContent}>
            <OrganizationWorkflowsWrapper orgId={id} />
        </div>
    );
};

export default OrganizationWorkflows;
