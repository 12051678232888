import moment from "moment";
import {
    ApplicationFilters,
    ApplicationFiltersStore,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import RootStore from "stores/RootStore";
import { IAgentContext } from "./IContext";

export enum UserSelectedContextType {
    AllConversations = "AllConversations",
    AppliedFilters = "AppliedFilters",
}

export interface IUserSelectedContext<T = unknown>
    extends IAgentContext<UserSelectedContextType, T> {
    label: string;
}

class AllConversationsContext implements IUserSelectedContext<undefined> {
    type: UserSelectedContextType.AllConversations;
    label: string;

    constructor() {
        this.type = UserSelectedContextType.AllConversations;
        this.label = "All Conversations";
    }

    public getValue(): undefined {}
}

class AppliedFiltersContext
    implements IUserSelectedContext<ApplicationFilters>
{
    type: UserSelectedContextType.AppliedFilters;
    label: string;

    constructor() {
        this.type = UserSelectedContextType.AppliedFilters;
        this.label = "Applied Filters";
    }

    public getValue(): ApplicationFilters {
        const store = RootStore().getStore(ApplicationFiltersStore);

        const filters = store.toFilterObject();

        filters.startDate = moment(filters.startDate).format(
            "YYYY-MM-DDTHH:mm:ss",
        );
        filters.endDate = moment(filters.endDate).format("YYYY-MM-DDTHH:mm:ss");

        if (filters.minCallDuration === 0 && filters.maxCallDuration === 0) {
            filters.minCallDuration = undefined;
            filters.maxCallDuration = undefined;
        }

        return filters;
    }
}

export namespace UserSelectedContext {
    export const AllConversations = new AllConversationsContext();
    export const AppliedFilters = new AppliedFiltersContext();
}
