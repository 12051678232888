import { ApplicationFilters } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { CallDirection } from "stores/ApplicationFilters/Filters/CallDirection";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import RootStore from "stores/RootStore";

export type AvaFilters = ApplicationFilters &
    Partial<{
        conversationIds: string[];
        themeIds?: string[];
        classifierId?: string;
        wordsPhrasesSearchString?: string;
        dateReference?: number;
        agentId?: string;
        callDirection?: CallDirection;
    }>;

export async function avaFiltersToApplicationFilters(
    filters: AvaFilters,
): Promise<ApplicationFilters> {
    if (filters.themeIds && filters.themeIds.length > 0) {
        const valuesStore = RootStore().getStore(ApplicationFilterValuesStore);

        await valuesStore.loadTopics();
        if (valuesStore.topicClusters) {
            const topics = valuesStore.topicClusters.filter((cluster) =>
                filters.themeIds!.includes(cluster.id),
            );
            filters.topics = topics;
        }
    }

    if (filters.classifierId) {
        filters.rbcFilterItems = [
            {
                value: true,
                classifierIds: [filters.classifierId],
                combinator: 0,
                filterGroupId: 0,
            },
        ];
    }

    if (filters.agentId) {
        filters.agentIds = [filters.agentId];
    }

    if (filters.wordsPhrasesSearchString) {
        filters.wordsAndPhrasesIsInputStandard = true;
        filters.wordsAndPhrasesSearchString = filters.wordsPhrasesSearchString;
        filters.wordsAndPhrasesItems = [
            {
                value: "Contains",
                searchPhrase: filters.wordsPhrasesSearchString,
                combinator: 0,
                filterGroupId: 0,
            },
        ];
    }

    if (filters.callDirection !== undefined) {
        filters.callDirections = [filters.callDirection];
    }

    // Ava used to use an enum for date ref option (differed from app filters model type),
    // now she uses the DateReferenceOption type which is an object with a value prop to correctly
    // match the app filters model. This number check probably is not necessary.
    if (typeof filters.dateReferenceOption === "number") {
        switch (filters.dateReferenceOption) {
            case 0:
                filters.dateReferenceOption =
                    DateReferenceOption.InteractionDate;
                break;
            case 1:
                filters.dateReferenceOption = DateReferenceOption.ArrivalDate;
                break;
        }
    } else if (
        typeof filters.dateReferenceOption === "object" &&
        !!filters.dateReferenceOption?.["value"]
    ) {
        filters.dateReferenceOption = filters.dateReferenceOption["value"];
    }

    return filters;
}
