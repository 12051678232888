import {
    Paper,
    Grid,
    Divider,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
} from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import React, { useState } from "react";
import theme from "Theme/AppTheme";

enum SourceFileType {
    Single = "single",
    Batch = "batch",
}

type DataSourceSetupProps = {};

const DataSourceSetup: React.FC<DataSourceSetupProps> = (props) => {
    const [sourceFileType, setSourceFileType] = useState<SourceFileType>(
        SourceFileType.Single,
    );
    const [isDebug, setIsDebug] = useState(false);

    return (
        <Paper sx={{ width: "100%", padding: "16px" }}>
            <Grid container item xs={12} direction="column" rowSpacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: theme.palette.blackFont,
                        paddingBottom: "8px",
                    }}
                >
                    Setup
                </Grid>
                <Divider />

                {/* Source Type Check Boxes */}
                <Grid item container flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            Source Type
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <RadioGroup
                            onChange={(e) => {
                                setSourceFileType(
                                    e.target.value as SourceFileType,
                                );
                            }}
                            value={sourceFileType}
                        >
                            <Grid container item xs={12}>
                                <FormControlLabel
                                    value={SourceFileType.Single}
                                    control={<Radio />}
                                    label="Single Metadata File"
                                    labelPlacement="end"
                                />

                                <FormControlLabel
                                    value={SourceFileType.Batch}
                                    control={<Radio />}
                                    label="Batch Metadata File"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Grid>

                {/* Configuration Name */}
                <Grid container item flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            Configuration Name
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <AcxMainTextField
                            fullWidth
                            key="datasources-configuration-name"
                            id="datasources-configuration-name"
                            value=""
                            placeholderText="Enter configuration name..."
                            onChange={() => {}}
                        />
                    </Grid>
                </Grid>

                {/* File Type */}
                <Grid container item flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            File Type
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <AcxMainTextField
                            fullWidth
                            isDisabled={true}
                            key="datasources-file-type"
                            id="datasources-file-type"
                            value="JSON"
                            onChange={() => {}}
                        />
                    </Grid>
                </Grid>

                {/* File Type Extension */}
                <Grid container item flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            File Type Extension
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <AcxMainTextField
                            fullWidth
                            isDisabled={true}
                            key="datasources-file-type-extension"
                            id="datasources-file-type-extension"
                            value={
                                sourceFileType === SourceFileType.Single
                                    ? "JSON_META"
                                    : "JSON_META_EXT"
                            }
                            onChange={() => {}}
                        />
                    </Grid>
                </Grid>

                {/* Chat File Schema Handler */}
                <Grid container item flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            Chat File Schema Handler
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <AcxSelect
                            fullWidth
                            id={"datasources-chat-file-schema-handler"}
                            options={[]}
                            labelField="label"
                            valueField="value"
                        />
                    </Grid>
                </Grid>

                {/* Is Debug */}
                <Grid container item flexDirection="row">
                    <Grid item xs={3}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: theme.palette.gray.main,
                                paddingBottom: "8px",
                            }}
                        >
                            Is Debug
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Switch
                            sx={{ width: 40, marginLeft: 0 }}
                            onChange={() => {
                                setIsDebug(!isDebug);
                            }}
                            checked={isDebug}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DataSourceSetup;
