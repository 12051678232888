import { Grid, IconButton, Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxCard from "components/UI/Cards/AcxCard";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import SignalsReportStore, {
    LoadTopicsOnTheRise,
} from "../Store/SignalsReportStore";
import { AcxViewSqlDialog } from "components/UI/AcxViewSqlDialog";
import { SignalsTrendingIndicator } from "./SignalsTrendingIndicator";
import AcxMenu, { StyledMenuLabel } from "components/UI/Menu/AcxMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles((theme: Theme) => ({
    cardsContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary[800],
        borderRadius: theme.shape.borderRadius,
    },
    topicsHeader: {
        color: theme.palette.white.main,
        paddingLeft: theme.spacing(1),
        fontWeight: 600,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontSize: "16px",
    },
    trendingIcon: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
    growthSubtitle: {
        fontSize: "8px",
        fontWeight: 600,
    },
}));

const cardRootStyle: React.CSSProperties = {
    borderRadius: theme.spacing(1),
    boxShadow: `0px 1px 8px 0px ${alpha(theme.palette.black.main, 0.05)}`,
};

const TopicsOnTheRise = observer(() => {
    const store = useStore(SignalsReportStore);
    const classes = useStyles();
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const [showSqlDialog, setShowSqlDialog] = React.useState(false);

    const isLoading = store.getTaskLoading(LoadTopicsOnTheRise);

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setMoreMenuAnchorEl(event.currentTarget);
    }

    function handleMoreMenuClose() {
        setMoreMenuAnchorEl(null);
    }

    const showSQL =
        !!store.topicsOnTheRiseSQL?.length && store.authStore.isUserUltra();

    return (
        <div
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Grid item container xs={12} className={classes.cardsContainer}>
                <Grid
                    item
                    container
                    xs={12}
                    className={classes.topicsHeader}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={showSQL ? "space-between" : "flex-start"}
                >
                    <Grid>Topics on the Rise</Grid>
                    {showSQL && (
                        <IconButton
                            id={`signals-topicsOnTheRise-Menu`}
                            style={{ color: theme.palette.white.main }}
                            onClick={handleMoreMenuClick}
                            size="small"
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </Grid>
                {!!store.topicsOnTheRise.length ? (
                    <Grid
                        item
                        container
                        spacing={2}
                        style={{ marginBottom: theme.spacing(1) }}
                    >
                        {store.topicsOnTheRise.map((i, index) => {
                            return (
                                <Grid
                                    key={index}
                                    item
                                    lg={3}
                                    md={6}
                                    style={{ zIndex: 1000 }} // set this to avoid card loading indicators appearing on top of header
                                >
                                    <AcxCard
                                        loading={isLoading}
                                        loadingSize={24}
                                        title={
                                            <Typography
                                                fontWeight="600"
                                                fontSize="16px"
                                                color={theme.palette.gray[500]}
                                                lineHeight="1.5"
                                            >
                                                {isLoading ? " " : i.topicName}
                                            </Typography>
                                        }
                                        rootStyle={cardRootStyle}
                                        headerStyle={{ paddingBottom: 0 }}
                                        mainContentStyle={{
                                            justifyContent: "start",
                                            paddingTop: 0,
                                            paddingBottom: theme.spacing(2),
                                        }}
                                        mainContent={
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                justifyContent={"space-between"}
                                                alignItems="center"
                                            >
                                                <Typography
                                                    fontWeight="600"
                                                    fontSize="20px"
                                                    lineHeight="32px"
                                                    color={
                                                        theme.palette.gray[600]
                                                    }
                                                >
                                                    {i.volume}
                                                </Typography>
                                                <Grid container item xs={10}>
                                                    <SignalsTrendingIndicator
                                                        inputValue={
                                                            i.percentChange
                                                        }
                                                        isNew={i.isNew}
                                                        showGrowthSubtitle
                                                        isNegativeRed
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <Grid
                        item
                        container
                        xs={12}
                        style={{ color: theme.palette.white.main }}
                    >
                        <Grid
                            item
                            container
                            justifyContent={"center"}
                            style={{ fontWeight: 600 }}
                        >
                            No data available
                        </Grid>
                        <Grid item container justifyContent={"center"}>
                            Try changing your filters.
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {showSQL && (
                <>
                    <AcxViewSqlDialog
                        title={`SQL Query for Topics On The Rise`}
                        sqlStatements={[store.topicsOnTheRiseSQL]}
                        isModalOpen={showSqlDialog}
                        onClose={() => setShowSqlDialog(false)}
                    />
                    <AcxMenu
                        anchorElement={moreMenuAnchorEl}
                        menuItems={[
                            {
                                id: "signals-show-sql-menu-item",
                                props: {
                                    onClick: () => {
                                        setShowSqlDialog(true);
                                        handleMoreMenuClose();
                                    },
                                    disabled: isLoading,
                                },
                                label: (
                                    <StyledMenuLabel>View SQL</StyledMenuLabel>
                                ),
                            },
                        ]}
                        onMenuClose={handleMoreMenuClose}
                    />
                </>
            )}
        </div>
    );
});

export default TopicsOnTheRise;
