import { styled } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import React from "react";
import { AgentLogo } from "./Branding/AgentLogo";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";

const GradientButton = styled(AcxButton)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    background:
        "linear-gradient(131deg, #32D1B7 3.43%, #349AC7 70.89%, #3664D6 100.5%)",
    columnGap: theme.spacing(1),
}));

interface AgentActionButtonProps
    extends React.ComponentProps<typeof GradientButton> {}

export function AgentActionButton({
    children,
    ...props
}: AgentActionButtonProps) {
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("View Ava")) return null;

    return (
        <GradientButton {...props}>
            <AgentLogo style={{ paddingRight: "5px" }} />
            {children}
        </GradientButton>
    );
}
