import { Grid, Typography } from "@mui/material";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Suggestion } from "../Suggestions/Suggestion";
import { useStore } from "utils/useStore";
import SignalsReportStore, {
    LoadDashboards,
} from "components/Signals/Store/SignalsReportStore";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { SignalsCreateDashboardForm } from "components/Signals/Components/SignalsCreateDashboardForm";
import { ISignalsUserDashboard } from "components/Signals/Models/SignalsModels";
import AcxButton from "components/UI/AcxButton";
import { useNavigate } from "react-router";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { avaFiltersToApplicationFilters } from "../AgentUtils";
import { toJS } from "mobx";
import RootStore from "stores/RootStore";

export const AddPreExistingGraphToDashboard = observer(({ args, remove }) => {
    const navigate = useNavigate();

    const signalsReportStore = useStore(SignalsReportStore);
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [selectedDashboard, setSelectedDashboard] =
        useState<ISignalsUserDashboard>();

    const [isDashboardsLoaded, setIsDashboardsLoaded] = useState(false);
    const [isAddLoading, setIsAddLoading] = useState(false);

    const isCreateDashboard = useMemo(
        () => !!signalsReportStore.newDashboardTitle,
        [signalsReportStore.newDashboardTitle],
    );

    const isFormComplete = useMemo(
        () => !!selectedDashboard || isCreateDashboard,
        [selectedDashboard, isCreateDashboard],
    );

    useEffect(() => {
        if (
            isDashboardsLoaded ||
            signalsReportStore.allDashboardsUserCanView.length > 0
        )
            return;
        signalsReportStore.refreshDashboards();
        setIsDashboardsLoaded(true);
    }, [signalsReportStore, isDashboardsLoaded]);

    const dashboardOptions = useMemo(() => {
        return signalsReportStore.allDashboardsUserCanView.filter(
            (dashboard) => {
                if (!!dashboard.pinnedApplicationFilterId) return false;
                // not the owner of the dashboard, then you can't add graphs to it!
                if (
                    dashboard.userId !==
                    signalsReportStore.authStore._user.profile.sub
                )
                    return false;

                return true;
            },
        );
    }, [
        signalsReportStore.allDashboardsUserCanView,
        signalsReportStore.authStore,
    ]);

    const addToDashboard = useCallback(async () => {
        let dashboardId: string | undefined;

        if (isCreateDashboard) {
            const dashboard = await signalsReportStore.createDashboard(false);
            dashboardId = dashboard?.id;
        } else {
            signalsReportStore.currentDashboard = selectedDashboard;
            dashboardId = selectedDashboard?.id;
        }

        if (!dashboardId)
            return signalsReportStore.messageStore.logError(
                "Could not find or create the specified dashboard.",
            );

        const vizDef = signalsReportStore.allChartData.find(
            (vd) => vd.dataEndpoint === args.chartId,
        );
        if (!vizDef) return;

        const vizDefToAdd = toJS(vizDef) as any;
        vizDefToAdd.dashboardId = dashboardId;
        vizDefToAdd.order = 0;
        await signalsReportStore.signalsService.addVizDefinitionsToDashboard(
            dashboardId,
            [vizDefToAdd],
        );
        await signalsReportStore.refreshDashboards();

        applicationFiltersStore.setSelectedSavedFilters();
        applicationFiltersStore.resetFilters();

        if (args.filters) {
            const filters = await avaFiltersToApplicationFilters(args.filters);
            applicationFiltersStore.applyExternalFilters(filters, true);
        }

        signalsReportStore.setViewToDashboard(dashboardId);

        remove();

        const dashboardUrlToNavigateTo = `/app/signals/dashboard/${dashboardId}`;
        const isAlreadyViewingDashboard =
            dashboardUrlToNavigateTo === RootStore().activeLocation.location;
        // there is a reaction in the signals report store that triggers when location changes (i.e. navigate is called).
        // If the location is the same, report requests are cancelled and filters restored which causes some wonky behavior.
        // We circumvent that by only navigating when we're not already viewing the target dashboard. Otherwise
        // manually reseting the abort controller and refresh cards/reports.
        if (!isAlreadyViewingDashboard) {
            navigate(dashboardUrlToNavigateTo);
        } else {
            signalsReportStore.resetAbortController();
            signalsReportStore.refreshReports();
        }

        // cards don't like to be reloaded on navigate but they should since its extremely
        // likely their filters changed when the Ava ones were applied.
        if (signalsReportStore.haveInitLoadedCards)
            signalsReportStore.refreshSignalsCards();
    }, [
        navigate,
        applicationFiltersStore,
        isCreateDashboard,
        selectedDashboard,
        signalsReportStore,
        args,
        remove,
    ]);

    if (!args.chartId || !args.filters) return null;

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Add to Dashboard"
                maxWidth="lg"
                dialogContentChildren={
                    <Grid container item direction="column" rowGap={3}>
                        <Grid item>
                            <Typography fontWeight="bold" fontSize="13px">
                                Select an existing dashboard
                            </Typography>
                            <AcxSelectSingle
                                id="ava-add-graph-to-dashboard"
                                options={dashboardOptions}
                                labelField="title"
                                valueField="id"
                                isLoading={signalsReportStore.getTaskLoading(
                                    LoadDashboards,
                                )}
                                isClearable
                                defaultValue={selectedDashboard}
                                onChange={(option) => {
                                    setSelectedDashboard(option);
                                }}
                                isDisabled={isCreateDashboard}
                                fullWidth
                            />
                        </Grid>
                        <Grid container item direction="column" rowGap={1}>
                            <Typography fontWeight="bold" fontSize="13px">
                                Or create a new dashboard
                            </Typography>
                            <SignalsCreateDashboardForm
                                disabled={!!selectedDashboard}
                            />
                        </Grid>
                    </Grid>
                }
            >
                <AcxButton
                    disabled={!isFormComplete}
                    onClick={async () => {
                        setIsAddLoading(true);

                        await addToDashboard();

                        setDialogOpen(false);
                        setSelectedDashboard(undefined);
                        signalsReportStore.newDashboardTitle = "";
                        signalsReportStore.newDashboardVis = "User";

                        setIsAddLoading(false);
                    }}
                    loading={isAddLoading}
                >
                    Add
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    {args?.buttonText || "Visualize this data over time"}
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
